import { useEffect, useState } from 'react';
import { IoMdClose } from 'react-icons/io';

interface ToastProps {
  time: number;
  message: string;
  onClose: () => void;
}

const Toast = ({ time, message, onClose }: ToastProps) => {
  const [isVisible, setIsVisible] = useState(!!message);

  const close = () => {
    setIsVisible(false);
    onClose();
  };

  useEffect(() => {
    if (message) {
      setIsVisible(true);
      const timeout = setTimeout(close, time);

      return () => clearTimeout(timeout);
    }
  }, [time, message]);

  if (!isVisible) return null;

  return (
    <div className="absolute top-1 right-1 z-[1003]">
      <div className="relative w-60 h-max bg-custom-bg rounded-md p-4 border-border border-[1px] shadow-lg">
        <IoMdClose
          data-testid="closeToast"
          className="cursor-pointer hover:text-cta p-1 stroke-[30px] bg-custom-bg absolute top-1 right-1"
          onClick={close}
          size={30}
        />
        <div>{message}</div>
      </div>
    </div>
  );
};

export { Toast };
