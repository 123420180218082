import { useEffect, useState, useRef, useId } from 'react';
import { useContent } from '@context/ContentProvider';
import { MdFullscreen, MdBlurOn, MdBlurOff } from 'react-icons/md';
import {
  FaForward,
  FaBackward,
  FaVolumeMute,
  FaVolumeUp
} from 'react-icons/fa';
import { FaPause, FaPlay } from 'react-icons/fa6';
import { Dropdown } from '@components/shared/Dropdown';
import { Dialog } from '@components/shared/Dialog';
import { TbPictureInPicture } from 'react-icons/tb';
import { VideoTimeline } from '@components/shared/Case/Content/VideoTimeline';
import { Content } from '@api/types';

type Props = {
  url: string;
  content?: Content;
  fieldId: string;
};

const VideoPlayer = ({ url, content, fieldId }: Props) => {
  const progressId = useId();
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoThumbRef = useRef<HTMLVideoElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const { contentBlur, contentGrayscale } = useContent();
  const [isBlurred, setIsBlurred] = useState(contentBlur);
  const [isGrayscaled, setIsGrayscaled] = useState(contentGrayscale);
  const [playSpeed, setPlaySpeed] = useState<number>(1);
  const [show, setShow] = useState<boolean>(false);
  const [isMuted, setIsMuted] = useState<boolean>(true);
  const [isPlaying, setIsPlaying] = useState<boolean>(true);

  useEffect(() => {
    setIsBlurred(contentBlur);
  }, [contentBlur]);

  useEffect(() => {
    setIsGrayscaled(contentGrayscale);
  }, [contentGrayscale]);

  const blur = Math.floor(Math.min(1, parseFloat(isBlurred)) * 15);

  const filter = `blur(${blur}px) grayscale(${isGrayscaled})`;

  const moveVideo = (direction: string) => {
    if (videoRef.current) {
      const { currentTime } = videoRef.current;
      if (direction === 'forward') {
        videoRef.current.currentTime = currentTime + 5;
      } else {
        videoRef.current.currentTime = currentTime - 5;
      }
    }
  };

  useEffect(() => {
    if (!videoRef.current) return;
    const video = videoRef.current;

    const progress = document.getElementById(`${progressId}-main`) as any;
    const progressFilled = document.getElementById(
      `${progressId}-filled`
    ) as any;
    const currentTime = () => {
      const currentTime = document.getElementById(`${progressId}-current`);
      const durationTime = document.getElementById(`${progressId}-duration`);
      const currentMinutes = Math.floor(video.currentTime / 60);
      const currentSeconds = Math.floor(
        video.currentTime - currentMinutes * 60
      );
      const durationMinutes = Math.floor(video.duration / 60);
      const durationSeconds = Math.floor(video.duration - durationMinutes * 60);

      if (currentTime && durationTime) {
        currentTime.innerHTML = `${currentMinutes}:${currentSeconds < 10 ? '0' + currentSeconds : currentSeconds}`;
        durationTime.innerHTML = `${durationMinutes}:${durationSeconds}`;
        const progressPercentage = (video.currentTime / video.duration) * 100;
        progressFilled.style.flexBasis = `${progressPercentage}%`;
        if (progressPercentage === 100) {
          setIsPlaying(true);
        }
      }
    };

    const handleProgressClick = (e: any) => {
      const progressTime =
        (e.offsetX / (progress?.offsetWidth || 0)) * video.duration;
      video.currentTime = progressTime;
    };

    if (progress) {
      progress.addEventListener('click', handleProgressClick);
    }
    video.addEventListener('timeupdate', currentTime);

    return () => {
      if (progress) {
        progress.removeEventListener('click', handleProgressClick);
      }
      video.removeEventListener('timeupdate', currentTime);
    };
  }, [progressId]);

  const onBlur = (e: any) => {
    e.preventDefault();
    setIsBlurred(contentBlur);
    setIsGrayscaled(contentGrayscale);
  };

  const onUnblur = (e: any) => {
    e.preventDefault();
    setIsBlurred('0');
    setIsGrayscaled('0');
  };

  const setSpeed = (val: number) => {
    if (videoRef.current) {
      videoRef.current.playbackRate = val;
      setPlaySpeed(val);
    }
  };

  useEffect(() => {
    if (!videoRef.current) return;
    const video = videoRef.current;

    const togglePlayPause = async () => {
      try {
        if (!isPlaying) {
          await video.play();
        } else {
          video.pause();
        }
      } catch (error) {
        console.error('Error with play/pause toggle:', error);
      }
    };

    togglePlayPause();
  }, [isPlaying]);

  useEffect(() => {
    if (!videoRef?.current) return;
    videoRef.current.muted = isMuted;
  }, [isMuted]);

  return (
    <div className="w-full">
      <div className="relative">
        <div className="flex gap-2 absolute z-40 right-0 flex-col p-2">
          <button
            onClick={() => setShow(true)}
            type="button"
            className="bg-custom-bg rounded-md w-8 h-8 flex items-center justify-center"
          >
            <MdFullscreen size="22" />
          </button>
          {parseFloat(isBlurred) || parseFloat(isGrayscaled) ? (
            <button
              onClick={onUnblur}
              className="bg-custom-bg rounded-md w-8 h-8 flex items-center justify-center shadow-md"
            >
              <MdBlurOff size="22" />
            </button>
          ) : (
            <button
              onClick={onBlur}
              className="bg-custom-bg rounded-md w-8 h-8 flex items-center justify-center shadow-md"
            >
              <MdBlurOn size="22" />
            </button>
          )}
        </div>
        <div
          onClick={() => setIsPlaying(!isPlaying)}
          className="relative flex justify-center bg-black rounded-t-md overflow-hidden"
          ref={parentRef}
        >
          <video
            ref={videoRef}
            preload="metadata"
            className="rounded-t-md w-full"
            style={{ filter }}
            controls={false}
            autoPlay={false}
            muted
          >
            <source src={url} type="video/mp4" />
          </video>

          <video
            ref={videoThumbRef}
            preload="metadata"
            style={{ display: 'none' }}
            controls={false}
            autoPlay={false}
            muted
          >
            <source src={url} type="video/mp4" />
          </video>
        </div>
      </div>
      <div
        id={`${progressId}-main`}
        className="flex items-center w-full bg-neutral-700 relative"
      >
        <progress id={`${progressId}-filled`} className="h-1 w-0">
          Progress
        </progress>
        <VideoTimeline
          videoThumbRef={videoThumbRef.current}
          videoRef={videoRef.current}
          content={content}
          fieldId={fieldId}
          parentRef={parentRef?.current}
        />
      </div>

      <div
        id="video-controls"
        className="px-3 py-0.5 flex gap-2 items-center bg-neutral-900 text-white w-full rounded-b-md"
      >
        <div className="flex items-baseline">
          <button
            onClick={() => setIsPlaying(!isPlaying)}
            type="button"
            className="pr-2"
          >
            {isPlaying ? <FaPlay size={10} /> : <FaPause size={13} />}
          </button>
          <button
            onClick={() => moveVideo('backward')}
            type="button"
            className="px-2"
          >
            <FaBackward size={12} />
          </button>
          <button
            onClick={() => moveVideo('forward')}
            type="button"
            className="px-2"
          >
            <FaForward size={12} />
          </button>
          <button
            onClick={() => setIsMuted(!isMuted)}
            type="button"
            className="px-2"
          >
            {!isMuted ? <FaVolumeUp size={12} /> : <FaVolumeMute size={12} />}
          </button>
          <Dropdown
            list={[
              { name: '2x', type: 2 },
              { name: '1.5x', type: 1.5 },
              { name: '1x', type: 1 },
              { name: '0.5x', type: 0.5 }
            ]}
            onAction={({ type }) => setSpeed(type)}
            title={`${playSpeed}x`}
            arrow={false}
            buttonStyle="text-xs min-w-8 flex justify-center"
            listStyle="zIndexTop absolute bottom-[30px] flex text-xs flex-col"
            itemStyle="text-white bg-neutral-900  w-8 hover:bg-neutral-700 text-center p-1"
          />
        </div>

        <div className="text-xs flex">
          <div id={`${progressId}-current`}>0:00</div>/
          <div id={`${progressId}-duration`}>0:00</div>
        </div>
        <button
          onClick={() => videoRef?.current?.requestPictureInPicture()}
          type="button"
        >
          <TbPictureInPicture />
        </button>
      </div>
      <Dialog
        dialogStyle="w-2/3 bg-custom-bg relative"
        show={show}
        close={() => setShow(false)}
      >
        <video
          controls={true}
          preload="metadata"
          className="rounded-t-md w-full"
          muted
        >
          <source src={url} type="video/mp4" />
        </video>
      </Dialog>
    </div>
  );
};

export { VideoPlayer };
