import { useState, ReactNode, ElementType } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

type Props = {
  children: ReactNode;
  title: string | ReactNode;
  icon?: ReactNode;
  show?: boolean;
  hasDivider?: boolean;
  startsOpen?: boolean;
  headerType?: ElementType;
};

const Accordion = ({
  children,
  title,
  icon = null,
  show = true,
  hasDivider = true,
  startsOpen = false,
  headerType = 'h5'
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(startsOpen);
  const HeaderTag = headerType;

  if (!show) return null;
  return (
    <>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center justify-between w-full"
        data-testid={title}
      >
        <HeaderTag className="flex items-center gap-3">
          {icon}
          {title}
        </HeaderTag>
        <div>
          {isOpen && <IoIosArrowUp size={22} />}
          {!isOpen && <IoIosArrowDown size={22} />}
        </div>
      </button>
      {isOpen && children}
      {hasDivider && <div className="border-b border-border my-3" />}
    </>
  );
};

export { Accordion };
