import { Button } from '@components/shared/Buttons';

type ListObjectType = {
  code: string;
  name: string;
  meta?: string[];
};

const PolicyListItems = ({
  list,
  itemSelected,
  selectPolicy
}: {
  list: ListObjectType[];
  itemSelected: string;
  selectPolicy: (code: string) => void;
}) => (
  <>
    {list?.map((item: ListObjectType) => (
      <div
        key={item.code}
        className={`${
          itemSelected === item?.code ? 'border-cta-hover' : 'border-custom-bg'
        } border-l-8`}
      >
        <Button
          style={`text-lg py-4 px-5 w-full text-left hover:bg-custom-bg hover:text-cta ${
            itemSelected === item?.code && 'text-cta font-bold'
          }`}
          key={item.code}
          onClick={() => selectPolicy(item?.code)}
        >
          <div className="flex flex-col">
            <div>{item.name}</div>
            {item?.meta?.map((x) => (
              <div
                className="truncate line-clamp-1 font-normal text-sm"
                key={x}
              >
                {x}
              </div>
            ))}
          </div>
        </Button>
      </div>
    ))}
  </>
);

export { PolicyListItems };
