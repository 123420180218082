import { ContentCase, Incident } from '@api/types';

const isNotAlreadyInquiried = (content: ContentCase) => {
  const incident = content?.incidents?.at(-1);
  const status = incident?.status;
  const causes = incident?.causes?.at(-1);
  const isInquired = status === 'open' && causes === 'inquiry';
  return !isInquired;
};

const getStatus = (incident?: Incident) => {
  if (!incident?.status) return 'No incident';
  if (incident.status === 'open') return 'Pending review';
  return ['enforced', 'terminated', 'upheld'].includes(
    incident.resolutions?.at(-1) || ''
  )
    ? 'Removed'
    : 'Approved';
};

export { isNotAlreadyInquiried, getStatus };
