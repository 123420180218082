import { useState } from 'react';
import { PlatformTime } from '@components/shared/PlatformTime';
import { IncidentStatus } from '@components/shared/IncidentStatus';
import { Dialog } from '@components/shared/Dialog';
import { CaseViewExtended } from '@components/shared/Case/Views/Extended';
import { ContentCaseShort, ContentCase } from '@api/types';
import { BulkReviewCase } from '@typeDef/Review';
import { Button } from '@components/shared/Buttons';
import { ContentFields } from '@components/shared/Case/Content';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@context/UserProvider';
import { FaEye } from 'react-icons/fa6';
import { TbVersionsFilled } from 'react-icons/tb';
import { FaUserCircle } from 'react-icons/fa';
import { InteractiveLabel } from '@components/shared/InteractiveLabel';
import { capitalizeFirstLetter } from '@utils/string';
import { getStatus } from '@components/shared/Case/Views/helper';

const CaseViewGrid = ({
  contentCase,
  selectedCS,
  setSelectedCS,
  isNotQA = true,
  queueId,
  showTranslate = true
}: {
  contentCase: ContentCaseShort;
  selectedCS?: Map<string, BulkReviewCase | ContentCase>;
  setSelectedCS?: (selectedCS: Map<string, any>) => void;
  isNotQA?: boolean;
  queueId?: string;
  showTranslate?: boolean;
}) => {
  const [show, setShow] = useState<boolean>(false);
  const content = contentCase?.contents?.at(-1);
  const incident = contentCase?.incidents?.at(-1);
  const selected = selectedCS?.has(contentCase?.cid);
  const navigate = useNavigate();
  const { platform } = useUser();

  const onClick = () => {
    if (!isNotQA) return;
    selected
      ? selectedCS?.delete(contentCase?.cid)
      : selectedCS?.set(contentCase?.cid, contentCase);
    setSelectedCS?.(new Map(selectedCS));
  };

  return (
    <div
      className={`border-border border rounded-md p-6 bg-custom-bg hover:shadow-md hover:bg-highlight ${selected && 'outline outline-cta bg-highlight'} ${isNotQA && 'cursor-pointer'} flex flex-col`}
      onClick={onClick}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onClick();
        }
      }}
    >
      <div className="flex flex-row w-full">
        <div className="flex items-center justify-between mb-2 w-full">
          {!Object.keys(contentCase).includes('_primary_term') && (
            <div className="flex gap-2 items-center">
              <IncidentStatus incident={incident} />
              <div>
                <div className="font-bold text-lg">{getStatus(incident)}</div>
                <PlatformTime dt={contentCase.ts} />
              </div>
            </div>
          )}

          <div className="flex gap-2 items-start">
            <Button
              type="icon h-8 w-8"
              onClick={(e) => {
                e.stopPropagation();
                setShow(true);
              }}
              hiddenTitle="View More"
            >
              <FaEye className="min-w-[10px]" data-testid="more" />
            </Button>
            <Button
              type="primarySmall justify-center"
              onClick={() => {
                navigate(
                  `/queues/${queueId ?? 'noqueue'}/review/event/${encodeURIComponent(contentCase.cid)}/${encodeURIComponent(contentCase.ct)}?pid=${platform?.id}`
                );
              }}
              hiddenTitle="Moderate case"
            >
              Moderate
            </Button>
          </div>
        </div>
      </div>
      <div
        className="flex flex-col h-[400px] overflow-auto items-center justify-between overflow-y-visible w-full text-left grow border-t border-border"
        onClick={onClick}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            onClick();
          }
        }}
      >
        <div className="overflow-y-visible break-words cursor-default">
          <ContentFields
            fields={content?.fields}
            showTranslate={showTranslate}
          />
        </div>
      </div>

      <footer className="grid grid-cols-5 border-t border-border pt-4 justify-between max-w-full gap-4">
        <div className="col-span-1">
          <InteractiveLabel
            tooltip="Click to copy"
            title={capitalizeFirstLetter(contentCase.ct)}
            style="font-semibold"
          />
        </div>
        <div className="col-span-2">
          <InteractiveLabel
            tooltip="Click to copy"
            title={contentCase.cid}
            icon={<TbVersionsFilled className="min-w-6" />}
            style=""
          />
        </div>
        <div className="col-span-2">
          {content?.author && (
            <InteractiveLabel
              tooltip="See author's cases"
              onClick={() =>
                navigate(`/explore?author=${content?.author || ''}`)
              }
              title={content?.author}
              icon={<FaUserCircle className="min-w-6" />}
              style=""
            />
          )}
        </div>
      </footer>

      <Dialog
        show={show}
        close={() => setShow(false)}
        dialogStyle="w-2/3 bg-custom-bg relative cursor-default"
      >
        <div className="px-6 pb-2">
          <CaseViewExtended contentCase={contentCase} setShow={setShow} />
        </div>
      </Dialog>
    </div>
  );
};

export { CaseViewGrid };
