import { useEffect, useState, ChangeEvent } from 'react';
import { Dropdown } from '@components/shared/Dropdown';
import { FieldViolation, Content, ContentField } from '@api/types';
import '@components/style.css';
import { Button } from '@components/shared/Buttons';
import { FaCheck } from 'react-icons/fa6';
import { BsArrowReturnLeft } from 'react-icons/bs';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';
import { PolicyList } from '@components/Review/DecisionTree/PolicyList';

const getField = (
  field: ContentField,
  parentId?: string
): string | string[] => {
  if (field.fields) return getFields(field.fields, field.id).concat(field.id);
  return parentId ? `${parentId}.${field.id}` : field.id;
};

const getFields = (fields: ContentField[], parentId?: string) => {
  return fields.map((field: ContentField) => getField(field, parentId)).flat();
};

const getProperties = (content: Content) => {
  if (!content) return [];
  if (content.fields) return getFields(content.fields);
  if (content.body) return Object.keys(content.body);
  return [];
};

type FieldsViolationType = {
  [key: string]: Set<string>;
};

const getPropertyList = (list: string[] = []): FieldsViolationType =>
  list.reduce((a, v) => ({ ...a, [v]: new Set() }), {});

const getViolations = (fieldViolations: FieldsViolationType) => {
  const list: FieldViolation[] = [];
  for (const [key, value] of Object.entries(fieldViolations)) {
    if (value instanceof Set) {
      const policyArray = [...value]; //
      policyArray.forEach((policy: string) =>
        list.push({
          policy: policy,
          field: key
        })
      );
    }
  }
  return { violations: list };
};

const PoliciesSelection = ({
  contentCase,
  selectedProperty,
  setSelectedProperty,
  setPayload,
  showConfirmRecap,
  setShowPolicyList,
  showPolicyList,
  setShowConfirmRecap,
  setSelectedAction
}: any) => {
  const [multiPolicySelection, setMultiPolicySelection] = useState<boolean>(
    window.localStorage.getItem('multiPolicySelection') === 'true'
  );
  const fields = getProperties(contentCase?.contents?.at(-1));
  const [fieldViolations, setFieldViolations] = useState<FieldsViolationType>(
    getPropertyList(fields)
  );

  const [violations, setViolations] = useState(new Set<string>());

  const onClick = (policyId: string) => {
    const violations = fieldViolations[selectedProperty] || new Set();

    if (violations.has(policyId)) {
      violations.delete(policyId);
    } else {
      violations.add(policyId);
    }

    setFieldViolations({ ...fieldViolations, [selectedProperty]: violations });
  };

  const onSelectPolicy = (policyId: string) => {
    violations.has(policyId)
      ? violations.delete(policyId)
      : violations.add(policyId);

    setViolations(new Set(violations));
  };

  useEffect(() => {
    window.localStorage.setItem(
      'multiPolicySelection',
      multiPolicySelection.toString()
    );
  }, [multiPolicySelection]);

  useEffect(() => {
    const payload = multiPolicySelection
      ? getViolations(fieldViolations)
      : { violations: [...violations]?.map((policy: string) => ({ policy })) };

    setPayload(payload);
  }, [fieldViolations, violations]);

  useEffect(() => {
    multiPolicySelection
      ? setViolations(new Set())
      : setFieldViolations(getPropertyList(fields));
  }, [multiPolicySelection]);

  useEffect(() => {
    return () => {
      setFieldViolations({});
      setViolations(new Set());
      setPayload();
    };
  }, []);

  const handleClick = (event: ChangeEvent<HTMLInputElement>) => {
    event.target.blur();
    setMultiPolicySelection(!multiPolicySelection);
  };

  const confirmPolicies = () => {
    if (violations?.size) {
      setShowConfirmRecap(true);
      return setShowPolicyList(false);
    }

    const hasFieldViolations = Object.values(fieldViolations).some(
      (x: Set<string>) => Boolean(x?.size)
    );

    if (hasFieldViolations) {
      setShowConfirmRecap(true);
      return setShowPolicyList(false);
    }
  };

  return (
    <>
      <div className="flex items-center mt-4">
        <div
          className={`${
            showConfirmRecap ? 'bg-cta' : 'ring-1 ring-cta bg-custom-bg'
          } flex-shrink-0 w-6 h-6 rounded-lg text-white flex items-center justify-center`}
        >
          {showConfirmRecap && <FaCheck />}
        </div>
        <div className="flex justify-between w-full items-center">
          <div className="ml-3 text-lg font-semibold">
            Select policy violations{' '}
          </div>
          <Slider
            handleClick={handleClick}
            multiPolicySelection={multiPolicySelection}
            fields={fields}
          />
        </div>
      </div>
      {showPolicyList && (
        <div className="mt-4">
          {!multiPolicySelection ? (
            <div className="relative before:absolute before:ml-[0.75rem] before:h-full before:border-l-[2px] before:border-dotted before:border-white before:bg-cta">
              <div className="ml-6">
                <PolicyList
                  onClick={onSelectPolicy}
                  confirmPolicies={confirmPolicies}
                  violations={violations}
                  setSelectedAction={setSelectedAction}
                />
                <Button
                  style="primarySmall h-10 w-full mt-2 justify-center"
                  onClick={confirmPolicies}
                  disabled={violations.size === 0}
                >
                  <kbd className="mr-2">
                    <BsArrowReturnLeft />
                  </kbd>{' '}
                  Confirm
                </Button>
              </div>
            </div>
          ) : (
            <div className="relative mt-1 before:absolute before:ml-[0.75rem] before:h-full before:border-l-[2px] before:border-dotted before:border-white before:bg-cta">
              <div className="ml-6">
                <Dropdown
                  list={fields}
                  buttonStyle="rounded-sm prevent-highlight"
                  onAction={setSelectedProperty}
                  title={selectedProperty}
                  listStyle="absolute z-10 ml-[-15px] w-72 mt-2 border rounded-sm overflow-hidden border-border"
                  itemStyle="hover:bg-highlight prevent-highlight"
                  mainClass="roundedContainer rounded-sm mb-2 mx-2 w-full bg-custom-bg hover:bg-highlight"
                />
                <PolicyList
                  onClick={onClick}
                  confirmPolicies={confirmPolicies}
                  violations={fieldViolations[selectedProperty]}
                  setSelectedAction={setSelectedAction}
                />
                <Button
                  type="secondary"
                  style="gap-2 justify-center font-bold h-9 mt-2 w-full"
                  onClick={confirmPolicies}
                  hiddenTitle={'policiesConfirmedBtn'}
                  disabled={
                    !Object.values(fieldViolations).filter(
                      (x: any) => !!x?.size
                    ).length
                  }
                >
                  <kbd>
                    <BsArrowReturnLeft />
                  </kbd>{' '}
                  Confirm
                </Button>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

type SliderProp = {
  fields: string[];
  multiPolicySelection: boolean;
  handleClick: (e: any) => void;
};

const Slider = ({ fields, multiPolicySelection, handleClick }: SliderProp) => {
  if (fields?.length > 1)
    return (
      <Tooltip text="Policies per Field">
        <label className="relative cursor-pointer ml-2 flex items-center">
          <input
            type="checkbox"
            checked={multiPolicySelection}
            onChange={handleClick}
            className="sr-only peer"
          />
          <div className="w-11 h-6 rounded-xl bg-cta-disabled border-2 border-cta-disabled peer peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:bg-custom-bg after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-2 after:border-white after:rounded-xl after:h-5 after:w-5 after:transition-all  peer-checked:bg-cta"></div>
        </label>
      </Tooltip>
    );
};

export { PoliciesSelection };
