import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useUser } from '@context/UserProvider';
import { TestAndIntegrateHeader } from '@components/TestAndIntegrate/TestAndIntegrateHeader';
import { TestAndIntegrateForm } from '@components/TestAndIntegrate/Form';
import { IngestCSV } from '@components/TestAndIntegrate/IngestCSV';
import { Loader } from '@components/shared/Loader';
import '@components/style.css';

const TestAndIntegrate = () => {
  const { platform, platforms, domainObjects, domainObject, isLoadingDomains } =
    useUser();
  const [test, setTest] = useState<string>('manual');
  const [data, setData] = useState();

  const staticData = useMemo(
    () => ({
      contentId: uuidv4(),
      userId: uuidv4()
    }),
    []
  );

  if (isLoadingDomains) return <Loader />;
  if (!platform || !platforms) return null;

  if (!domainObject)
    return (
      <>
        <TestAndIntegrateHeader />
        <h4 className="pl-8">Please select a Platform with Domain Object</h4>
      </>
    );

  return (
    <>
      <TestAndIntegrateHeader setTest={setTest} setData={setData} />
      <div className="mt-4 p-8">
        {test === 'manual' && (
          <div className="w-full">
            <TestAndIntegrateForm
              domainObject={domainObject}
              domainObjects={domainObjects}
              staticData={staticData}
            />
          </div>
        )}
        {test === 'csv' && data && (
          <IngestCSV
            domainObject={domainObject}
            domainObjects={domainObjects}
            data={data}
          />
        )}
      </div>
    </>
  );
};

export default TestAndIntegrate;
