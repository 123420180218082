import { useState } from 'react';
import CurlPreview from '@components/CurlPreview';
import { RetrieveApiKey } from '@components/shared/RetrieveApiKey';
import { MdDelete } from 'react-icons/md';
import { Dropdown } from '@components/shared/Dropdown';
import { Button } from '@components/shared/Buttons';
import { useApiService } from '@api/services';
import { IngestContentField, SimpleType, DomainObject } from '@api/types';
import { Dialog } from '@components/shared/Dialog';
import { v4 as uuidv4 } from 'uuid';
import { ShowNewProperty } from '@components/TestAndIntegrate/ShowNewProperty';

type Field = {
  id: string;
  type: string;
  src: string;
};

const TestAndIntegrateForm = ({
  domainObject,
  staticData,
  domainObjects
}: any) => {
  const { postContent } = useApiService();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [isIngestionOK, setIsIngestionOK] = useState<boolean>();
  const [showAddParent, setShowAddParent] = useState<boolean>(false);
  const [showAddAuthor, setShowAddAuthor] = useState<boolean>(false);
  const [parent, setParent] = useState<any>();
  const [author, setAuthor] = useState<any>();
  const [fields, setFields] = useState<IngestContentField[]>([
    { id: '', type: SimpleType.TEXT, src: '' }
  ]);

  const addField = () =>
    setFields([...fields, { id: '', type: SimpleType.TEXT, src: '' }]);

  const handleChange = (e: any, index: number) => {
    const data = [...fields] as any;
    data[index][e.target.name] = e.target.value;
    setFields(data);
  };

  const onChangeParent = (value: string, key: string) =>
    setParent({ ...parent, [key]: value });

  const onChangeAuthor = (value: string) => setAuthor(value);

  const removeFields = (index: number) => {
    const data = [...fields];
    data.splice(index, 1);
    setFields(data);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    postContent.mutate(
      {
        id: uuidv4(),
        type: domainObject.type,
        fields,
        ...(parent && { parent }),
        ...(author && { author })
      },
      {
        onSettled: async () => setShowDialog(true),
        onSuccess: async () => setIsIngestionOK(true),
        onError: async () => setIsIngestionOK(false)
      }
    );
  };

  const onSelectAction = (type: string) => {
    if (type === 'Parent') setShowAddParent(true);
    if (type === 'Field') addField();
    if (type === 'Author') setShowAddAuthor(true);
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <h4>Generate an API call</h4>
        <div className="flex justify-end gap-3">
          <Dropdown
            buttonStyle="border border-border rounded-md p-2.5 bg-custom-bg"
            listStyle="border border-border absolute rounded-md overflow-hidden min-w-24"
            list={['Field', 'Parent', 'Author']}
            title={'Add new'}
            onAction={onSelectAction}
          />
        </div>
      </div>
      <div className="my-3">
        <form
          data-testid="form"
          className="flex gap-4 flex-wrap justify-between"
          onSubmit={handleSubmit}
        >
          {fields.map((field: Field, index: number) => (
            <div className="flex gap-3 w-full items-center" key={index}>
              <Dropdown
                key={index}
                list={Object.values(SimpleType)}
                onAction={(i) =>
                  handleChange({ target: { name: 'type', value: i } }, index)
                }
                title={field.type}
                mainClass="rounded-md border-border border align-center flex relative align-center p-2.5"
                buttonStyle="w-28 whitespace-nowrap hover:bg-custom-bg border-border"
                listStyle="absolute zIndexTop rounded-md border border-border top-12 overflow-auto left-0 w-44"
                itemStyle="hover:bg-custom-bg hover:text-cta"
              />
              <input
                type="text"
                name="id"
                id="id"
                data-testid="fieldId"
                className="border border-border bg-custom-bg rounded-md block w-28 p-2.5"
                placeholder="field Id"
                value={field.id}
                onChange={(e) => handleChange(e, index)}
                required
              />
              <input
                type="text"
                name="src"
                id="src"
                data-testid="fieldSrc"
                className="border border-border bg-custom-bg rounded-md block w-full p-2.5"
                placeholder="field src"
                value={field.src}
                onChange={(e) => handleChange(e, index)}
                required
              />
              <div>
                <MdDelete
                  onClick={() => removeFields(index)}
                  className="cursor-pointer icon"
                  color="red"
                  size="28"
                />
              </div>
            </div>
          ))}
          <ShowNewProperty
            setShowAddParent={setShowAddParent}
            setParent={setParent}
            setShowAddAuthor={setShowAddAuthor}
            setAuthor={setAuthor}
            author={author}
            parent={parent}
          />
          <div className="w-full flex justify-end py-3 gap-2">
            <Button onClick={() => {}} type="primarySmall">
              Ingest Content
            </Button>
          </div>
        </form>
      </div>
      <CurlPreview
        title="Integrate API"
        showDocs={true}
        payload={{
          id: staticData.contentId,
          author: author || staticData.userId,
          type: domainObject?.type,
          fields: JSON.stringify(fields),
          parent: parent
        }}
      />
      <RetrieveApiKey />
      <Dialog
        show={showAddParent}
        close={() => setShowAddParent(false)}
        dialogStyle="w-2/5 bg-custom-bg"
      >
        <div className="flex flex-col gap-4">
          <h5>Select the parent field and type</h5>
          <div className="w-full flex gap-3">
            <input
              type="text"
              name="src"
              id="src"
              data-testid="fieldSrc"
              className="border border-border bg-custom-bg rounded-md block w-full p-2.5"
              placeholder="field src"
              value={parent?.id}
              onChange={(e) => onChangeParent(e.target.value, 'id')}
              required
            />
            <Dropdown
              buttonStyle="border border-border rounded-md p-2.5 bg-custom-bg"
              mainClass="w-full"
              listStyle="border border-border absolute rounded-md overflow-hidden min-w-44"
              list={domainObjects || []}
              title={parent?.type || '-- Please select the Type --'}
              onAction={(option: DomainObject) =>
                onChangeParent(option.type, 'type')
              }
            />
          </div>
          <div className="flex justify-end">
            <Button
              onClick={() => setShowAddParent(false)}
              title="Confirm"
              type="primary"
              style="h-10"
              disabled={!parent?.id || !parent?.type}
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        show={showAddAuthor}
        close={() => setShowAddAuthor(false)}
        dialogStyle="w-2/5 bg-custom-bg"
      >
        <div className="flex flex-col gap-4">
          <h5>Select the Author</h5>
          <input
            type="text"
            name="src"
            id="src"
            data-testid="fieldSrc"
            className="border border-border bg-custom-bg rounded-md block w-full p-2.5"
            placeholder="field src"
            value={author}
            onChange={(e) => onChangeAuthor(e.target.value)}
            required
          />
          <div className="flex justify-end">
            <Button
              onClick={() => setShowAddAuthor(false)}
              title="Confirm"
              type="primary"
              style="h-10"
              disabled={!author}
            />
          </div>
        </div>
      </Dialog>

      <Dialog
        show={showDialog}
        close={() => setShowDialog(false)}
        dialogStyle="w-2/5 bg-custom-bg"
      >
        {isIngestionOK ? (
          <>
            <div className="font-semibold text-xl mb-4">Content submitted</div>
            <div className="text-lg">
              Your content should appear in Explore page shortly
            </div>
          </>
        ) : (
          <>
            <div className="font-semibold text-xl mb-4">Error found</div>
            <div className="text-lg">Please try again</div>
          </>
        )}
      </Dialog>
    </div>
  );
};

export { TestAndIntegrateForm };
