import { useState, useEffect, useRef } from 'react';
import { useApiService } from '@api/services';
import { Pagination } from '@components/shared/Pagination';
import { Loader } from '@components/shared/Loader';
import { useSearchParams } from 'react-router-dom';
import { ContentFilterControl } from '@components/shared/ContentFilterControl';
import { CaseViewGrid } from '@components/shared/Case/Views/CaseViewGrid';
import { ModalInquiry } from '@components/shared/ModalInquiry';
import { useAnalytics } from '@context/AnalyticsProvider';
import { Button } from '@components/shared/Buttons';
import { SearchFilter } from './SearchFilter';
import { HotkeysProvider } from 'react-hotkeys-hook';
import {
  SearchResultResponse,
  SearchResultCountsResponse,
  ContentCase
} from '@api/types';
import { defaultFilters, getSearchPayload } from '@components/Explore/helper';
import { useUser } from '@context/UserProvider';
import { DatePicker } from '@components/Dashboard/DatePicker';
import { useDate } from '@context/DateProvider';
import { CaseViewList } from '@components/shared/Case/Views/CaseViewList';
import { FaTh, FaTint } from 'react-icons/fa';
import { FaListUl } from 'react-icons/fa6';
import { useClickOutside } from '@hooks/useClickOutside';

const MAX_CONTENTCASES_ALLOWED = 9500;

const Explore = () => {
  const { sendEvent } = useAnalytics();
  const { useSearchDocument, useSearchTimeCounts, useStrategies } =
    useApiService();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCS, setSelectedCS] = useState<Map<string, ContentCase>>(
    new Map()
  );
  const [isInquiryModalVisible, setIsInquiryModalVisible] = useState(false);
  const [documentList, setDocumentList] = useState<SearchResultResponse>();
  const [chartData, setChartData] = useState<SearchResultCountsResponse>();
  const [isSearchQuery, setIsSearchQuery] = useState<boolean>(false);
  const [isListView, setIsListView] = useState<boolean>(
    localStorage.getItem('ViewSetting') === 'true' || false
  );
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [expandAll, setExpandAll] = useState<boolean>(false);
  const { platform, user } = useUser();
  const { endDate, startDate } = useDate();
  const { data: strategyList } = useStrategies();

  const scrollRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLInputElement>(null);

  const [internalState, setInternalState] = useState<any>(new Map());
  useEffect(() => {
    scrollRef?.current?.scrollIntoView?.({ behavior: 'smooth' });
  }, [internalState.get('page')]);

  useEffect(() => {
    localStorage.setItem('ViewSetting', JSON.stringify(isListView));
  }, [isListView]);

  // Init reads url to persist and share sessions
  useEffect(() => {
    const state = new Map();
    searchParams.forEach((value, key) => {
      if (defaultFilters.includes(key)) {
        return state.has(key)
          ? state.get(key).add(value)
          : state.set(key, new Set([value]));
      }
      if (value) {
        state.set(key, value);
      }
      if (key === 'startDate') {
        state.set('startDate', startDate?.toISODate());
      }
      if (key === 'endDate') {
        state.set('endDate', endDate?.toISODate());
      }
    });
    if (!state.has('size')) {
      state.set('size', 24);
      state.set('start', 0);
    }

    setInternalState(new Map(state));
  }, [
    searchParams.get('startDate'),
    searchParams.get('endDate'),
    searchParams.get('labels'),
    searchParams.get('author'),
    searchParams.get('parentId'),
    searchParams.get('parentComplexType')
  ]);

  // API trigger if state changes
  useEffect(() => {
    if (internalState.size > 0) {
      fetchDocuments();
    }
  }, [internalState, platform?.id]);

  useClickOutside(dropdownRef, () => setShowSettings(false), showSettings);

  const setState = (updatedState: any) => {
    if (updatedState.get('page') === internalState.get('page')) {
      updatedState.set('page', 0);
    }
    setInternalState(new Map(updatedState));
  };
  const setPaginationState = (val: number | string, key: string) => {
    const state = new Map(internalState);
    state.set(key, val);
    setState(new Map(state));
  };

  const updateParams = () => {
    const params: any = {};
    for (const [key, value] of internalState) {
      if (value) {
        params[key] =
          typeof value === 'string' || typeof value === 'number'
            ? value
            : [...value];
      }
    }
    setSearchParams(params);
  };

  const fetchDocuments = () => {
    const payload = getSearchPayload(internalState);
    setSelectedCS(new Map());

    useSearchDocument.mutate(payload, {
      onSuccess: async (data) => {
        internalState.get('q') &&
          sendEvent('user search', { query: internalState.get('q') });
        setDocumentList(data);
        updateParams();
      }
    });

    useSearchTimeCounts.mutate(payload, {
      onSuccess: async (data) => {
        setChartData(data);
      }
    });
  };

  const count =
    chartData?.contentCases?.count &&
    chartData?.contentCases?.count > MAX_CONTENTCASES_ALLOWED
      ? MAX_CONTENTCASES_ALLOWED
      : chartData?.contentCases?.count;

  return (
    <HotkeysProvider>
      <div className="flex justify-center w-full mt-3 px-8">
        <div className="w-full">
          <div className="flex flex-col items-center relative">
            {useSearchDocument.isPending && (
              <div className="absolute top-20 mr-9">
                <Loader />
              </div>
            )}
            <div className="w-full">
              <div
                className="flex-col flex py-6 rounded-md items-center"
                ref={scrollRef}
              >
                <div className="w-full flex gap-3 items-center flex-row mb-3 h-11">
                  <SearchFilter
                    internalState={internalState}
                    setInternalState={setState}
                    searchDocument={fetchDocuments}
                    inputQuery={internalState?.get('q') || ''}
                    strategyList={strategyList}
                  />
                  <div className="min-w-max h-full min-h-11">
                    <DatePicker />
                  </div>
                  <div className="max-h-[44px] empty:hidden">
                    {internalState?.get('q')?.length > 2 &&
                      !!count &&
                      selectedCS.size === 0 && (
                        <Button
                          type="primary"
                          style="w-max h-11"
                          onClick={() => {
                            setIsSearchQuery(true);
                            setIsInquiryModalVisible(true);
                          }}
                          hiddenTitle="Open search inquiry modal"
                        >
                          Open incidents (search)
                        </Button>
                      )}
                    {selectedCS.size > 0 && (
                      <Button
                        type="primary"
                        style="h-11 w-max gap-2"
                        onClick={() => {
                          setIsSearchQuery(false);
                          setIsInquiryModalVisible(true);
                        }}
                        hiddenTitle="Bulk Inquiry"
                      >
                        <>
                          Open incidents{' '}
                          {!![...selectedCS].length && (
                            <span>({selectedCS.size})</span>
                          )}
                        </>
                      </Button>
                    )}
                  </div>
                </div>
                <div className="flex w-full justify-between items-center mt-3">
                  <h5 className="w-full">
                    Total events{' '}
                    {count === MAX_CONTENTCASES_ALLOWED
                      ? `${MAX_CONTENTCASES_ALLOWED}+`
                      : count}
                  </h5>
                  {user?.org !== 'trustpilot' && (
                    <div className="relative mr-2" ref={dropdownRef}>
                      <Button
                        hiddenTitle="toggle-blur-dropdown"
                        style="icon cursor-pointer w-11 h-11 border-border border"
                        onClick={() => setShowSettings(!showSettings)}
                      >
                        <FaTint size={20} />
                      </Button>
                      {showSettings && (
                        <Button
                          style="absolute border-border border bg-custom-bg w-44 right-0 top-11 p-3 rounded-md"
                          onClick={(e) => e.stopPropagation()}
                        >
                          <ContentFilterControl
                            showCheckbox={isListView}
                            onChange={() => setExpandAll(!expandAll)}
                            value={expandAll}
                            title="Expand all"
                          />
                        </Button>
                      )}
                    </div>
                  )}
                  <div className="flex justify-between">
                    <Button
                      style={`w-11 h-11 border border-e-0 border-border flex justify-center rounded-e-[0px] icon cursor-pointer ${!isListView ? 'bg-cta text-white' : ''}`}
                      onClick={() => setIsListView(false)}
                    >
                      <FaTh size={22} />
                    </Button>
                    <Button
                      style={`w-11 h-11 border border-s-0 border-border flex justify-center rounded-s-[0px] icon cursor-pointer ${isListView ? 'bg-cta text-white' : ''}`}
                      onClick={() => setIsListView(true)}
                    >
                      <FaListUl size={22} />
                    </Button>
                  </div>
                </div>
              </div>
              {!useSearchDocument.isPending && (
                <div
                  className={`w-full ${!isListView ? 'grid gap-3 grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4' : 'flex-col'} rounded-md`}
                >
                  {documentList?.contentCases?.map((x: ContentCase) =>
                    !isListView ? (
                      <CaseViewGrid
                        key={x.cid}
                        contentCase={x}
                        setSelectedCS={setSelectedCS}
                        selectedCS={selectedCS}
                        showTranslate={false}
                      />
                    ) : (
                      <CaseViewList
                        key={x.cid}
                        contentCase={x}
                        setSelectedCS={setSelectedCS}
                        selectedCS={selectedCS}
                        showTranslate={false}
                        expand={expandAll}
                      />
                    )
                  )}
                </div>
              )}
              <div className="flex justify-center w-full py-8">
                <Pagination
                  currentPage={parseInt(internalState.get('page')) || 0}
                  pageCount={
                    count ? Math.ceil(count / internalState.get('size')) : 1
                  }
                  pageSize={internalState.get('size')}
                  setPageSize={(x: number) => setPaginationState(x, 'size')}
                  gotoPage={(x: number) => setPaginationState(x, 'page')}
                />
              </div>
            </div>
            <ModalInquiry
              isInquiryModalVisible={isInquiryModalVisible}
              toggleInquiryModal={() => setIsInquiryModalVisible(false)}
              selectedCS={selectedCS}
              clearSelectedEvents={() => setSelectedCS(new Map())}
              searchQuery={getSearchPayload(internalState)}
              isSearchQuery={isSearchQuery}
            />
          </div>
        </div>
      </div>
    </HotkeysProvider>
  );
};

export default Explore;
