import { ChangeEvent, useState } from 'react';
import {
  ContentField,
  Content,
  MetadataElement,
  TranslateRequest
} from '@api/types';
import { ContentImage } from '@components/shared/Case/Content/ContentImage';
import { HiOutlineDocumentRemove } from 'react-icons/hi';
import { Dialog } from '@components/shared/Dialog';
import { TextContent } from '@components/shared/Case/Content/TextContent';
import { VideoPlayer } from '@components/shared/Case/Content/Video';
import { MetadataValue } from '@components/shared/Case/Content/MetadataValue';
import { useApiService } from '@api/services';
import { Button } from '@components/shared/Buttons';

type PropTypes = {
  val: string;
  width?: string;
  onClick?: () => void;
};

const FieldTypes = {
  text: ({ val }: PropTypes) => <TextContent val={val} />,
  image: ({ val, width = '300' }: PropTypes) => (
    <ContentImage key={val} content={val} width={width} />
  ),
  video: ({ val, content, fieldId }: any) => (
    <VideoPlayer key={val} url={val} content={content} fieldId={fieldId} />
  ),
  uri: ({ val }: PropTypes) => (
    <div key={val} className="p-2">
      {val}
    </div>
  ),
  file: ({ val }: PropTypes) => (
    <div className="p-2 flex gap-3 w-full justify-center">
      <a
        target="_blank"
        href={val}
        rel="noreferrer"
        className="primary break-words font-bold"
      >
        View file
      </a>
    </div>
  ),
  audio: ({ val }: PropTypes) => (
    <audio controls id={val} src={val} preload="auto" />
  )
} as any;

type FieldProp = {
  field: ContentField;
  fieldId?: string;
  width?: string;
  selectedProperty?: string;
  setSelectedProperty?: (val: string) => void;
  content?: Content;
};
const FieldRenderer = ({
  field,
  fieldId,
  width,
  selectedProperty,
  setSelectedProperty,
  content
}: FieldProp) => {
  const [show, setShow] = useState<boolean>(false);
  const onClick = () => setShow(true);

  if (!field) return null;

  const onSelect = () => {
    if (setSelectedProperty && fieldId) setSelectedProperty(fieldId);
  };
  const isSelectable = selectedProperty && setSelectedProperty;

  const isMediaType = field.type == 'image' || field.type == 'video';

  return (
    <>
      <div
        className={`my-1 w-full p-1.5 ${isSelectable ? 'contentOverlay' : ''} justify-center ${isMediaType ? 'flex' : 'text-center'}`}
        onMouseEnter={onSelect}
        onClick={(e) => e.stopPropagation()}
        onKeyDown={() => null}
      >
        {FieldTypes[field.type]({
          val: field.src,
          width,
          content,
          onClick,
          fieldId
        })}
        {!!field.metadata?.length && (
          <div className="text-xs ml-2 mb-4 text-left">
            {field.metadata?.map((meta: MetadataElement) => (
              <span key={`${fieldId}-${meta.value}`} className="mr-2">
                [<span className="font-bold mr-1">{meta.id}:</span>
                <span>
                  <MetadataValue value={meta.value} />]
                </span>
              </span>
            ))}
          </div>
        )}
      </div>
      <Dialog show={show} close={() => setShow(false)}>
        <embed id={field.src} src={field.src} className="w-full h-[80vh]" />
      </Dialog>
    </>
  );
};

type Props = {
  fields?: ContentField[];
  selectedProperty?: string;
  setSelectedProperty?: (val: string) => void;
  parentId?: string;
  showTranslate?: boolean;
  content?: any;
};

const ContentFields = ({
  fields = [],
  selectedProperty,
  setSelectedProperty,
  parentId,
  showTranslate = true,
  content
}: Props) => {
  const [translatedFields, setTranslatedFields] = useState();
  const [isTranslation, setIsTranslation] = useState(false);
  const { postTranslation } = useApiService();

  const getTranslation = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (translatedFields) return setIsTranslation(!isTranslation);
    postTranslation.mutate(
      { fields: fields as TranslateRequest },
      {
        onSuccess: async ({ data }) => {
          setIsTranslation(true);
          setTranslatedFields(data.fields);
        }
      }
    );
  };

  const getTitleTranslate = () => {
    if (postTranslation?.isPending) {
      return 'Pending...';
    }
    return isTranslation ? 'Show original' : 'Show translation';
  };

  if (!fields?.length)
    return (
      <div className="text-center my-4">
        <HiOutlineDocumentRemove size={40} />
        <h5 className="mt-2">No contents to display</h5>
      </div>
    );

  const width = '100%';

  const fieldsToRender =
    isTranslation && translatedFields ? translatedFields : fields;

  return (
    <>
      {fieldsToRender?.map((field: ContentField) => {
        const fieldId = parentId ? `${parentId}.${field?.id}` : field?.id;
        return (
          <div key={fieldId}>
            <FieldRenderer
              field={field}
              fieldId={fieldId}
              width={width}
              selectedProperty={selectedProperty}
              setSelectedProperty={setSelectedProperty}
              content={content}
            />
            {field?.fields?.length && (
              <ContentFields
                fields={field.fields}
                selectedProperty={selectedProperty}
                setSelectedProperty={setSelectedProperty}
                parentId={field.id}
              />
            )}
          </div>
        );
      })}
      {fields && showTranslate && (
        <div className="w-full zIndexTop pt-2">
          <Button
            hiddenTitle="triggered translation btn"
            style="text-cta"
            title={getTitleTranslate()}
            onClick={getTranslation}
          />
        </div>
      )}
    </>
  );
};

export { ContentFields };
