import { Incident } from '@api/types';
import { FaExclamation } from 'react-icons/fa6';
import { FaCheck, FaTimes } from 'react-icons/fa';
import { getStatus } from '@components/shared/Case/Views/helper';

type incidentStatus = {
  incident?: Incident;
  style?: string;
  size?: 'small' | 'large';
};

const getIconColor = (status?: string) => {
  if (status === 'Removed') return 'bg-error-light';
  if (status === 'Approved' || status === 'No incident')
    return 'bg-success-light';
  if (status === 'Pending review') return 'bg-alert-light';
};

const IncidentStatus = ({
  incident,
  style,
  size = 'large'
}: incidentStatus) => {
  const sizeGuide = {
    small: { icon: 12, padding: 'p-2' },
    large: { icon: 24, padding: 'p-3' }
  };
  const iconSize = sizeGuide[size].icon;
  const status = getStatus(incident);
  return (
    <div
      className={`${style} ${getIconColor(status)} ${sizeGuide[size].padding} my-1 rounded-xl flex max-h-[50px]`}
    >
      {status === 'Removed' && (
        <FaTimes size={iconSize} className="text-fg-2" />
      )}
      {status === 'Approved' && (
        <FaCheck size={iconSize} className="text-success" />
      )}
      {status === 'Pending review' && (
        <FaExclamation size={iconSize} className="text-fg-2" />
      )}
      {status === 'No incident' && (
        <FaCheck size={iconSize} className="text-fg-2" />
      )}
    </div>
  );
};

export { IncidentStatus };
