import { useState, useEffect, useRef } from 'react';
import { Evaluation, Content } from '@api/types';

type MarkerType = {
  time: number;
  violation: string;
};

const vttTimeToSeconds = (vttTime: string): number => {
  const timeParts = vttTime.split(':');

  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  let milliseconds = 0;

  if (timeParts.length === 3) {
    const [h, m, s] = timeParts;
    hours = parseInt(h, 10);
    minutes = parseInt(m, 10);
    const [sec, ms] = s.split('.');
    seconds = parseInt(sec, 10);
    milliseconds = ms ? parseInt(ms, 10) : 0;
  } else if (timeParts.length === 2) {
    const [m, s] = timeParts;
    minutes = parseInt(m, 10);
    const [sec, ms] = s.split('.');
    seconds = parseInt(sec, 10);
    milliseconds = ms ? parseInt(ms, 10) : 0;
  }

  const totalSeconds =
    hours * 3600 + minutes * 60 + seconds + milliseconds / 1000;

  return totalSeconds;
};

type Props = {
  videoThumbRef: HTMLVideoElement | null;
  videoRef: HTMLVideoElement | null;
  content?: Content;
  fieldId: string;
  parentRef?: any;
};

const VideoTimeline = ({
  videoRef,
  videoThumbRef,
  content,
  fieldId,
  parentRef
}: Props) => {
  const [markers, setMarkers] = useState<MarkerType[]>([]);
  const [hoverDetails, setHoverDetails] = useState<{
    time: number | null;
    marker: MarkerType | null;
  }>({ time: null, marker: null });

  useEffect(() => {
    const loadVTTFile = () => {
      const markers = content?.evaluations
        ?.filter(
          (x: Evaluation) =>
            x.field === fieldId && x.explanation?.data?.frame_ts
        )
        .reduce((acc: any[], x: any) => {
          const time = vttTimeToSeconds(x.explanation.data.frame_ts);
          const violation = x.label;

          const existingEntry = acc.find((entry) => entry.time === time);

          existingEntry
            ? (existingEntry.violation += `, ${violation}`)
            : acc.push({ time, violation });

          return acc;
        }, []);

      if (markers) {
        setMarkers(markers);
      }
    };

    loadVTTFile();
  }, []);

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const hoverPosition = event.clientX - rect.left;
    const hoverPercentage = hoverPosition / rect.width;
    const hoverTime = hoverPercentage * (videoThumbRef?.duration ?? 0);
    const threshold = (videoThumbRef?.duration ?? 0) * 0.02;

    // Check if hovering near a marker
    const closestMarker = markers.find(
      (marker) => Math.abs(marker.time - hoverTime) < threshold
    );

    setHoverDetails({
      time: closestMarker ? closestMarker.time : hoverTime,
      marker: closestMarker || null
    });
  };

  const handleMouseLeave = () => {
    setHoverDetails({ time: null, marker: null });
  };

  const getPercentage = (time: number, duration?: number) =>
    duration ? Math.round((time / duration) * 100) : 0;

  const getPointer = (time: number) => ({
    left: `${getPercentage(time, videoThumbRef?.duration)}%`
  });

  const getThumbAlignment = (time: number) => {
    const { duration } = videoThumbRef || {};
    const parentWidth = parentRef?.offsetWidth || 0;

    if (!duration || !parentWidth) return;

    const perc = getPercentage(time, duration);
    const pxLeft = (parentWidth * perc) / 100;
    const pxRight = parentWidth - pxLeft;

    if (pxLeft < 114) {
      return { left: '105px' };
    }

    if (pxRight < 114) {
      return { left: `${parentWidth - 105}px` };
    }

    return { left: `${(parentWidth * perc) / 100}px` };
  };

  return (
    <div className="absolute left-0 right-0 w-full h-2">
      <div
        className="relative w-full bg-gray-300 h-2"
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onKeyDown={(e) => {
          if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            const adjustment = e.key === 'ArrowLeft' ? -1 : 1;
            videoRef &&
              (videoRef.currentTime = Math.max(
                0,
                Math.min(videoRef.duration, videoRef.currentTime + adjustment)
              ));
          }
        }}
        tabIndex={0}
      >
        {markers.map((marker) => (
          <button
            key={`${marker.time}-${marker.violation}`}
            data-testid="timelineMarker"
            className="absolute w-2 h-2 bg-error rounded-xl zIndexTop"
            style={getPointer(marker.time)}
            onClick={() => {
              if (videoRef) {
                videoRef.currentTime = marker.time;
              }
            }}
          />
        ))}
      </div>
      {hoverDetails.time !== null && (
        <div
          className="absolute w-52 bg-black rounded-md p-0.5 bottom-[120%]"
          style={{
            ...getThumbAlignment(hoverDetails.time),
            transform: 'translateX(-6.5rem)'
          }}
        >
          <Thumbnail
            videoRef={videoThumbRef}
            time={hoverDetails.time}
            marker={hoverDetails.marker}
          />
        </div>
      )}
    </div>
  );
};

const Thumbnail = ({ videoRef, time, marker }: any) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const video = videoRef;
  const videoHeight = video?.videoHeight;
  const videoWidth = video?.videoWidth;
  const containerWidth = videoWidth * 0.3;
  const canvasWidth = containerWidth;

  const takeScreenshot = (time: number) => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (!video || !canvas || !context || !isFinite(time)) {
      return;
    }

    video.currentTime = time;

    video.onseeked = () => {
      const aspectRatio = videoWidth / videoHeight;
      const canvasHeight = containerWidth / aspectRatio;

      canvas.width = canvasWidth;
      canvas.height = canvasHeight;

      context.drawImage(
        video,
        0,
        0,
        videoWidth,
        videoHeight,
        0,
        0,
        canvasWidth,
        canvasHeight
      );
    };
  };

  useEffect(() => takeScreenshot(time), [time]);

  return (
    <div className="flex flex-col rounded-sm overflow-hidden">
      {marker && (
        <div className="mb-1 text-center text-sm text-white">
          <div>{marker.violation}</div>
        </div>
      )}
      <canvas ref={canvasRef} />
    </div>
  );
};

export { VideoTimeline, vttTimeToSeconds };
