import { useNavigate } from 'react-router-dom';
import { FiList } from 'react-icons/fi';
import { Button } from '@components/shared/Buttons';
import { DateTime } from 'luxon';
import { Platform } from '@api/types';
import { FaCalendarAlt } from 'react-icons/fa';
import { shortenCount } from '@utils/string';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';

type ageBucket = {
  key: string;
  value: number;
};

const QueueCard = ({
  platform,
  queue,
  title,
  count,
  ageBuckets,
  earliestEvent
}: {
  platform: Platform;
  queue: number;
  title: string;
  count: number;
  ageBuckets: ageBucket[];
  earliestEvent: string;
}) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => navigate(`/queues/${queue}/review?pid=${platform?.id}`)}
      className="flex justify-between items-center h-[70px] border-b border-border cursor-pointer"
    >
      <div className="flex items-center">
        <div className="w-[220px] line-clamp-2 mr-2">{title}</div>
        <div className="flex flex-row gap-2 mr-4 items-center">
          <div className="flex items-center gap-0.5">
            {ageBuckets?.map((bucket) => (
              <div
                key={bucket.key}
                className="w-[85px] py-2 px-3 h-[60px] overflow-hidden bg-bg-1 flex flex-col first:rounded-s-md last:rounded-e-md"
              >
                <div className="text-2lg font-semibold">
                  {shortenCount(bucket.value)}
                </div>
                <div className="text-xs light:text-fg-2 whitespace-nowrap overflow-visible">
                  {bucket.key}
                </div>
              </div>
            ))}
          </div>
          <h2 className="text-2lg font-semibold">:</h2>
          <div className="w-[80px] p-2 h-[60px] overflow-hidden bg-bg-3 flex flex-col rounded-md">
            <div
              className="text-2lg font-semibold"
              data-testid={`count-${count}`}
            >
              {shortenCount(count)}
            </div>
            <div className="text-xs light:text-fg-2">Total</div>
          </div>
        </div>
        <div className="text-base">
          <div className="hidden lg:flex light:text-fg-2">
            <Tooltip text={'Earliest content in this queue'} textStyle="w-44">
              <div className="flex gap-1 items-center">
                <FaCalendarAlt size={15} className="flex flex-shrink-0 mr-1" />
                {DateTime.fromISO(earliestEvent)
                  .setZone(platform?.timezone)
                  .toFormat('dd LLL yyyy, HH:mm')}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="flex gap-3">
        <Button
          type="primarySmall"
          onClick={(e: any) => {
            e.stopPropagation();
            navigate(`/queues/${queue}/review?pid=${platform?.id}`);
          }}
          hiddenTitle="Open single event"
        >
          Review
        </Button>
        <Button
          type="icon"
          style="w-8"
          onClick={(e: any) => {
            e.stopPropagation();
            navigate(`/queues/bulklist/${queue}?pid=${platform?.id}`);
          }}
          hiddenTitle="Open queue list"
        >
          <FiList size="18" />
        </Button>
      </div>
    </div>
  );
};

export { QueueCard };
