import { useState, ReactNode, useEffect } from 'react';
import { Button } from '@components/shared/Buttons';
import { useUser } from '@context/UserProvider';
import { marked } from 'marked';
import { Policy } from '@api/types';
import { FaHistory, FaPencilAlt } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';
import { RulesHistory } from '@components/Policy/PolicyActions/RulesHistory';
import { Dialog } from '@components/shared/Dialog';
import { PolicyAuditHistory } from '@components/Policy/PolicyAuditHistory';

type PolicyViewType = {
  policy: Policy;
  showAdd?: boolean;
  setShowAdd?: (val: boolean) => void;
  showRules?: boolean;
  children?: ReactNode;
  setShowEditDescription?: (val: boolean) => void;
  showEditDescription?: boolean;
  preview?: boolean;
  tabActive?: string;
  setTabActive?: (val: string) => void;
};

const PolicyView = ({
  policy,
  showAdd,
  setShowAdd,
  showRules = false,
  children,
  setShowEditDescription,
  showEditDescription,
  preview,
  tabActive,
  setTabActive
}: PolicyViewType) => {
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const { user } = useUser();

  useEffect(() => {
    if (tabActive !== 'description') {
      setTabActive?.('description');
    }
  }, [policy?.code]);

  return (
    <div className="py-6">
      <div className="flex justify-between">
        <div
          className={`flex justify-between w-full ${tabActive === 'rules' ? 'mb-8' : ''}`}
        >
          <div className="flex gap-4 text-lg items-baseline">
            <Button
              onClick={() => setTabActive?.('description')}
              style={`hover:text-cta ${tabActive === 'description' && 'text-cta border-b-2 font-semibold border-cta pb-2 mb-8'}`}
              title="Description"
            />
            {(policy?.guidelines || !preview) && (
              <Button
                style={`hover:text-cta ${tabActive === 'guidelines' && 'text-cta border-b-2 font-semibold border-cta pb-2 mb-8'}`}
                onClick={() => setTabActive?.('guidelines')}
                title="Internal guidelines"
              />
            )}

            {showRules && (
              <Button
                style={`hover:text-cta ${tabActive === 'rules' && 'text-cta border-b-2 font-semibold border-cta pb-2'}`}
                onClick={() => setTabActive?.('rules')}
                title="Rules"
              />
            )}
          </div>
          {user?.isUserAdmin && !preview && (
            <div className="flex flex-row gap-3 h-min">
              {tabActive !== 'rules' && (
                <Button
                  hiddenTitle="Edit policy description"
                  onClick={() => setShowEditDescription?.(!showEditDescription)}
                  type="secondarySmall"
                >
                  <FaPencilAlt size="14" className="mr-2" /> Edit
                </Button>
              )}
              {tabActive !== 'guidelines' && (
                <Button
                  hiddenTitle="show policy history"
                  onClick={() => setShowHistory?.(!showHistory)}
                  type="secondarySmall"
                >
                  <FaHistory size="14" className="mr-2" /> History
                </Button>
              )}
              {tabActive === 'rules' && (
                <Button
                  type="primarySmall"
                  hiddenTitle="add policy Rule"
                  onClick={() => setShowAdd?.(!showAdd)}
                >
                  <FaPlus size="14" className="mr-2" />
                  New rule
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
      {tabActive === 'description' && (
        <div
          id="policy"
          className="prose prose-strong:text-text-white max-w-none text-text-white gap-8"
          dangerouslySetInnerHTML={{
            __html: marked.parse(policy?.description)
          }}
        />
      )}
      {tabActive === 'guidelines' && (
        <div
          id="policy"
          className="prose prose-strong:text-text-white max-w-none text-text-white gap-8"
          dangerouslySetInnerHTML={{
            __html: marked.parse(policy?.guidelines || '')
          }}
        />
      )}
      {tabActive === 'rules' && <div>{children}</div>}
      <Dialog
        show={showHistory}
        close={() => setShowHistory(false)}
        dialogStyle="w-3/4 bg-custom-bg"
      >
        {tabActive === 'rules' ? (
          <RulesHistory policy={policy.code} />
        ) : (
          <PolicyAuditHistory policy={policy} />
        )}
      </Dialog>
    </div>
  );
};

export { PolicyView };
