import { useEffect, useState } from 'react';
import { PolicyListItems } from '@components/Policy/PolicyListItems';
import { useApiService } from '@api/services';
import { marked } from 'marked';
import { Policy, PolicyVersion } from '@api/types';
import { Button, SubmitButtons } from '@components/shared/Buttons';
import { FaPaperPlane } from 'react-icons/fa6';
import { Loader } from '@components/shared/Loader';
import { useQueryClient } from '@tanstack/react-query';
import { Dialog } from '@components/shared/Dialog';
import { formatVersionTime } from './helper';

const PolicyAuditHistory = ({ policy }: { policy: Policy }) => {
  const [itemSelected, setItemSelected] = useState<string>(
    policy?.version?.toString() ?? '1'
  );
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [versionSelected, setVersionSelected] = useState<PolicyVersion>();
  const { usePolicyVersions, postPolicyVersion } = useApiService();
  const queryClient = useQueryClient();

  const { data: policyVersions, isLoading } = usePolicyVersions({
    code: policy?.code
  });

  const updatePolicyDescription = (version?: PolicyVersion) => {
    version &&
      postPolicyVersion.mutate(
        {
          code: policy.code,
          payload: {
            name: version.name,
            description: version.description
          }
        },
        {
          onSuccess: async () => {
            Promise.all([
              queryClient.invalidateQueries({ queryKey: ['policies'] }),
              queryClient.invalidateQueries({ queryKey: ['Policy'] }),
              queryClient.invalidateQueries({
                queryKey: ['policyVersions']
              })
            ]).then(() => {
              setShowConfirmation(false);
            });
          },
          onError: (err) => {
            console.log('error', err);
          }
        }
      );
  };

  const policiesNormalised = policyVersions?.map((version) => ({
    code: version.version.toString(),
    name: formatVersionTime(version.timestamp || ''),
    meta: [`${version?.author?.email ?? 'checkstep'}`, `v${version.version}`]
  }));
  useEffect(() => {
    const selected = policyVersions?.find(
      (version) => version.version.toString() === itemSelected
    );
    selected && setVersionSelected(selected);
  }, [itemSelected, policyVersions]);

  if (isLoading) return <Loader />;

  return (
    <div>
      <h4 className="w-full border-b border-border pb-4">
        Description History
      </h4>
      <div className="grid grid-cols-8 w-full mt-8">
        <div className="flex flex-col col-span-2">
          <PolicyListItems
            list={policiesNormalised || []}
            itemSelected={itemSelected}
            selectPolicy={setItemSelected}
          />
        </div>
        <div className="flex flex-col gap-4 col-span-6">
          <div className="w-full border-b border-border mb-2 flex justify-between items-center pb-4">
            <div>
              <div className="text-md font-semibold">
                {formatVersionTime(versionSelected?.timestamp ?? '')}
              </div>
              <div className="font-normal text-xs">
                {versionSelected?.author?.email ?? 'checkstep'}
              </div>
              <div className="font-semibold text-xs">
                v{versionSelected?.version}
              </div>
            </div>
            <Button
              type="primarySmall"
              style="h-min"
              onClick={() => setShowConfirmation(true)}
            >
              <FaPaperPlane size={14} className="mr-2" /> Re-publish
            </Button>
          </div>
          <h5>{versionSelected?.name}</h5>
          <div
            id="policy"
            className="prose prose-strong:text-text-white max-w-none text-text-white gap-8"
            dangerouslySetInnerHTML={{
              __html: marked.parse(versionSelected?.description ?? '')
            }}
          />
        </div>
      </div>
      <Dialog
        show={showConfirmation}
        close={() => setShowConfirmation(false)}
        dialogStyle="w-1/3 bg-custom-bg"
      >
        <h4 className="w-full border-b border-border pb-4">
          Re-publish this version?
        </h4>
        <div className="flex gap-2 w-full justify-end mt-5">
          <SubmitButtons
            onSubmit={() => updatePolicyDescription(versionSelected)}
            submitLabel={'Confirm'}
            onClose={() => setShowConfirmation(false)}
            isLoading={postPolicyVersion?.isPending}
          />
        </div>
      </Dialog>
    </div>
  );
};

export { PolicyAuditHistory };
