import { useEffect } from 'react';
import { SubmitButtons } from '@components/shared/Buttons';
import { useForm } from 'react-hook-form';
import { useApiService } from '@api/services';
import { useQueryClient } from '@tanstack/react-query';

type Policy = {
  code: string;
  name: string;
  description: string;
  guidelines?: string;
};

type Props = {
  policy: Policy;
  onClose: () => void;
};

const EditGuildlines = ({ policy, onClose }: Props) => {
  const queryClient = useQueryClient();
  const { register, handleSubmit, reset, formState } = useForm({
    defaultValues: policy
  });
  const { putPolicyGuidelines } = useApiService();

  useEffect(() => {
    reset(policy);
  }, [policy]);

  const updatePolicyGuildlines = (data: any) => {
    putPolicyGuidelines.mutate(
      {
        code: data.code,
        guidelines: data.guidelines
      },
      {
        onSuccess: async () =>
          Promise.all([
            queryClient.invalidateQueries({ queryKey: ['policies'] }),
            queryClient.invalidateQueries({ queryKey: ['Policy'] })
          ]).then(() => onClose()),
        onError: (err) => {
          console.log('error', err);
        }
      }
    );
  };

  return (
    <div className="p-4 max-h-[50%]">
      <h2 className="mb-5">Edit Guidelines</h2>
      <form onSubmit={handleSubmit(updatePolicyGuildlines)}>
        <textarea
          className="flex-grow resize-none focus:outline-none bg-custom-bg roundedContainer w-full h-[50vh] overflow-auto"
          {...register('guidelines', { required: false })}
        />
        <div className="flex gap-2 mt-6 justify-end">
          <SubmitButtons
            onClose={onClose}
            onSubmit={() => handleSubmit(updatePolicyGuildlines)}
            submitLabel="Confirm"
            disabled={!formState.isValid}
            isLoading={false}
            submitStyle="font-bold py-2"
          />
        </div>
      </form>
    </div>
  );
};

export { EditGuildlines };
