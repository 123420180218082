import { Auth } from '@aws-amplify/auth';
import { useEffect, useState } from 'react';
import { useUser } from '@context/UserProvider';
import { useForm } from 'react-hook-form';
import { Header } from '@components/shared/Header';
import '@components/style.css';
import { useApiService } from '@api/services';
import { Loader } from '@components/shared/Loader';
import { Dropdown } from '@components/shared/Dropdown';
import { Language } from '@api/types';
import { useQueryClient } from '@tanstack/react-query';
import { Toast } from '@components/shared/Toast';

type formState = {
  oldPassword: string;
  newPassword: string;
  newPasswordRe: string;
};

export default function Profile() {
  const { user } = useUser();
  const { useSettings, putSettings } = useApiService();
  const { data, isPending } = useSettings();
  const [submitResult, setSubmitResult] = useState<any>({});
  const [errorMsg, setErrorMsg] = useState<string>('');
  const queryClient = useQueryClient();
  const [form] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordRe: ''
  });
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: form });

  useEffect(() => {
    reset(form);
  }, []);

  const passwordStrengthRegex =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
  const handlePassword = (data: formState) => {
    const { oldPassword, newPassword } = data;
    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword).then(() => {
          setSubmitResult({
            submit: 'Password successfully changed.'
          });
          reset(form);
        });
      })
      .catch((err) => {
        if (err.message === 'Incorrect username or password.') {
          setSubmitResult({
            oldPassword: 'Incorrect old password'
          });
        } else {
          setSubmitResult({
            submitError: err.response
          });
        }
      });
  };

  const onSelectLanguage = (lang: Language) => {
    putSettings.mutate(
      { translationLanguage: lang.code },
      {
        onSuccess: async () => {
          queryClient.invalidateQueries({ queryKey: ['userSettings'] });
        },
        onError: async () => {
          setErrorMsg('Failed to submit change, please try again later.');
        }
      }
    );
  };

  const getLangName = () => {
    const langName = data?.availableLanguages.find(
      (l: Language) => l.code === data.translationLanguage
    );
    return langName ? langName.name : 'ciao';
  };

  if (isPending) return <Loader />;
  if (!user) return null;

  return (
    <>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">Settings</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px]">Profile Settings</h2>
        </div>
      </Header>
      <div className="flex gap-3 flex-col mlg:flex-row px-8 pb-4">
        <div className="w-full gap-12 flex flex-col">
          <div>
            <h4 className="my-4">General</h4>
            <div className="flex flex-col gap-4">
              <div>
                <div className="my-1 font-bold">Role</div>
                <input
                  className="roundedContainer w-full focus:outline-none"
                  type="text"
                  value={user.role}
                  readOnly
                />
              </div>
              <div>
                <div className="my-1 font-bold">Email</div>
                <input
                  className="roundedContainer w-full focus:outline-none"
                  type="text"
                  value={user.email}
                  readOnly
                />
              </div>
            </div>
          </div>
          {data && (
            <div>
              <h4 className="my-4">Language preferences</h4>
              <div className="font-bold my-1">Translation language</div>
              <Dropdown
                list={data.availableLanguages}
                title={getLangName()}
                onAction={onSelectLanguage}
                buttonStyle="rounded-md border border-border p-2"
                listStyle="rounded-md border border-border max-h-96 overflow-auto"
              />
            </div>
          )}
        </div>

        <div className="w-full">
          <h4 className="my-4">Change password</h4>
          <div>
            <form
              onSubmit={handleSubmit(handlePassword)}
              className="flex flex-col gap-4"
            >
              <div>
                <div className="my-1 font-bold">Old Password</div>
                <input
                  type="password"
                  className="roundedContainer w-full focus:outline-none"
                  {...register('oldPassword', {
                    required: 'This is a required'
                  })}
                />
                {errors.oldPassword && (
                  <p className="text-red">{errors.oldPassword.message}</p>
                )}
                {submitResult.oldPassword && (
                  <p className="text-red">{submitResult.oldPassword}</p>
                )}
              </div>
              <div>
                <div className="my-1 font-bold">New Password</div>
                <input
                  type="password"
                  className="roundedContainer w-full focus:outline-none"
                  {...register('newPassword', {
                    required: 'This is required',
                    validate: (value) => {
                      return passwordStrengthRegex.test(value) &&
                        value.length >= 12
                        ? true
                        : 'Minimum 12 characters, lowercase letters, uppercase letters, numbers and special character.';
                    }
                  })}
                />
                {errors.newPassword && (
                  <p className="text-red">{errors.newPassword.message}</p>
                )}
              </div>
              <div>
                <div className="my-1 font-bold">New Password Again</div>
                <input
                  type="password"
                  className="roundedContainer w-full focus:outline-none"
                  {...register('newPasswordRe', {
                    required: 'This is required',
                    validate: {
                      matchesPreviousPassword: (value) => {
                        const { newPassword } = getValues();
                        return newPassword === value
                          ? true
                          : 'Passwords should match';
                      }
                    }
                  })}
                />
                {errors.newPasswordRe && (
                  <p className="text-red">{errors.newPasswordRe.message}</p>
                )}
              </div>

              <div className="flex justify-end">
                <button className="primary mt-4 font-bold py-2">
                  Update password
                </button>
              </div>

              {submitResult.submit && (
                <p className="text-red mt-2">{submitResult.submitError}</p>
              )}

              {submitResult.submit && (
                <p className="mt-2 text-success">{submitResult.submit}</p>
              )}
            </form>
          </div>
        </div>
        <Toast time={7000} message={errorMsg} onClose={() => setErrorMsg('')} />
      </div>
    </>
  );
}
