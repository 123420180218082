import { useState, useEffect } from 'react';
import { Loader } from '@components/shared/Loader';
import { Header } from '@components/shared/Header';
import { Button } from '@components/shared/Buttons';
import { useNavigate } from 'react-router-dom';
import { useApiService } from '@api/services';
import { strategiesIcons } from '@components/Policy/helper';
import { Input } from '@components/shared/Input';
import { Strategy } from '@api/types';

const Strategies = () => {
  const { useStrategies } = useApiService();
  const { data: strategies, isLoading: isLoadingStrategies } = useStrategies();
  const [search, setSearch] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [labels, setLabels] = useState<Set<string>>(new Set());

  const navigate = useNavigate();

  const getLabels = (strategies: Strategy[]): Set<string> =>
    new Set(
      strategies.flatMap(
        (p: Strategy) => p.labels?.filter((x: string) => !!x) || []
      )
    );

  const onSearch = (e: any) => {
    const { value } = e.target;
    setSearch(value);
    value ? setIsOpen(true) : setIsOpen(false);
  };

  useEffect(() => {
    if (!strategies) return;
    setLabels(getLabels(strategies));
  }, [strategies]);

  if (isLoadingStrategies) return <Loader />;

  return (
    <div>
      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">Settings</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px]">Strategies</h2>
        </div>
      </Header>
      <div className="relative px-8 my-2">
        <Input
          placeholder="Filter strategy by label"
          label=""
          type="text"
          htmlFor="label"
          value={search}
          onChange={onSearch}
        />
        {isOpen && (
          <div className="absolute zIndex-50 w-[88%] border border-border w-full max-h-60 rounded-md overflow-y-scroll shodow-lg">
            {[...labels]
              ?.filter((x: any) =>
                x?.toLowerCase().includes(search.toLowerCase())
              )
              .map((x: any) => (
                <button
                  key={x}
                  className="bg-custom-bg p-3 w-full flex"
                  onClick={() => {
                    setSearch(x);
                    setIsOpen(false);
                  }}
                >
                  <span>{x}</span>
                </button>
              ))}
          </div>
        )}
      </div>
      <div
        className="mb-4 grid gap-4 px-8 pt-2"
        style={{
          gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))'
        }}
      >
        {strategies
          ?.filter((strategy: Strategy) => {
            if (!search && !strategy.labels?.length) return strategy;
            return strategy?.labels?.some((label: string) =>
              label.toLowerCase().includes(search.toLowerCase())
            );
          })
          ?.map((strategy: Strategy) => (
            <Button
              key={strategy?.id}
              onClick={() => navigate(`${strategy?.id}`)}
              style="bg-bg-3 min-w-[320px] h-36 rounded-lg flex items-center flex-grow hover:bg-border cursor-pointer flex-col px-6 overflow-hidden"
            >
              <div className="h-28 flex flex-col justify-evenly my-3 items-start w-full">
                <div className="flex flex-row justify-between w-full">
                  <img
                    width="30"
                    alt="strategy-logo"
                    className="max-w-[30px]"
                    src={strategiesIcons[strategy.type] || '/favicon-32x32.png'}
                  />
                </div>
                <h6>{strategy?.name}</h6>
                <div className="text-md font-normal">{strategy?.slug}</div>
              </div>
            </Button>
          ))}
      </div>
    </div>
  );
};

export default Strategies;
