import {
  Moderator,
  Queue,
  Policy,
  IncidentCause,
  IncidentStatusOptions,
  DomainObject,
  QualityCheckOptions,
  Strategy
} from '@api/types';

type InputSearchOptionsType = {
  moderators?: Moderator[];
  queues?: Queue[];
  policies?: Policy[];
  isAdmin?: boolean;
  domainObjects?: DomainObject[];
  strategyList?: Strategy[];
};

const resolutionsList = [
  'enforced',
  'dismissed',
  'terminated',
  'upheld',
  'overturned'
];
const getInputSearchOption = ({
  moderators,
  queues,
  policies,
  isAdmin,
  domainObjects,
  strategyList
}: InputSearchOptionsType) => {
  if (!queues?.length || !policies?.length || !domainObjects?.length) return;

  const searchOptionList = new Map();

  const standardiseList = (array: any, id: any, name: any) => {
    if (!array) return new Map();
    return new Map(
      array.map((x: any) => [
        x[id].toString(),
        { id: x[id].toString(), name: x[name] }
      ])
    );
  };

  const mods = standardiseList(moderators, 'id', 'email');
  const que = standardiseList(queues, 'id', 'name');
  const contentTypes = standardiseList(domainObjects, 'type', 'type');
  const violations = standardiseList(policies, 'code', 'name');
  const labels = new Map(
    strategyList
      ?.map((x) => x.labels)
      .flat()
      .map((x) => [x, { id: x, name: x }])
  );

  const resolutions = new Map(
    resolutionsList.map((x) => [x, { name: x, id: x }])
  );
  const causes = new Map(
    Object.values(IncidentCause)?.map((x) => [x, { name: x, id: x }])
  );
  const status = new Map(
    Object.values(IncidentStatusOptions)?.map((x) => [x, { name: x, id: x }])
  );
  const qc = new Map(
    Object.values(QualityCheckOptions)?.map((x) => [x, { name: x, id: x }])
  );

  isAdmin && searchOptionList.set('moderators', mods);
  searchOptionList.set('queues', que);
  searchOptionList.set('contentTypes', contentTypes);
  searchOptionList.set('resolutions', resolutions);
  searchOptionList.set('causes', causes);
  searchOptionList.set('status', status);
  searchOptionList.set('violations', violations);
  searchOptionList.set('qualityCheck', qc);
  searchOptionList.set('labels', labels);

  return searchOptionList;
};

const defaultFilters = [
  'moderators',
  'queues',
  'contentTypes',
  'resolutions',
  'causes',
  'violations',
  'labels'
];

const isSingleValueFilter = (key: string) =>
  [
    'qualityCheck',
    'status',
    'author',
    'parentId',
    'parentComplexType'
  ].includes(key);

const getSearchPayload = (state: any) => {
  if (!state) return {};

  const searchOptionList = {} as any;

  for (const [key, value] of state) {
    if (defaultFilters.includes(key)) {
      handleDefaultFilters(key, value, searchOptionList);
    } else {
      handleNonDefaultFilters(key, value, searchOptionList, state);
    }
  }

  return searchOptionList;
};

const handleDefaultFilters = (
  key: string,
  value: any,
  searchOptionList: any
) => {
  searchOptionList[key]
    ? searchOptionList[key].push(value)
    : (searchOptionList[key] = [...value]);
};

const handleNonDefaultFilters = (
  key: string,
  value: any,
  searchOptionList: any,
  state: any
) => {
  key === 'page'
    ? (searchOptionList['start'] = value ? value * state.get('size') : 0)
    : (searchOptionList[key] = value);
};

export {
  getInputSearchOption,
  defaultFilters,
  getSearchPayload,
  isSingleValueFilter
};
