import { useApiService } from '@api/services';
import { MetricsCardProp, SummaryMetricsProp } from '@typeDef/Dashboard';
import { getMetricsDescription } from '@components/Dashboard/helper';
import { DurationUnit, formatDuration } from '@utils/date';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';
import { SummaryMetric } from '@api/types';
import { FaInfoCircle } from 'react-icons/fa';
import { Doughnut } from 'react-chartjs-2';

const MetricsCard = ({
  label,
  description,
  tooltip,
  score,
  isMain,
  chartData,
  overLabel
}: MetricsCardProp) => {
  if (!score?.toString()) return null;
  const chartMetric = chartData ? parseFloat((chartData * 100).toFixed(2)) : 0;
  const data = {
    labels: [label, overLabel],
    datasets: [
      {
        data: [chartMetric, chartMetric >= 100 ? 0 : 100 - chartMetric],
        backgroundColor: ['rgb(99 102 241)', 'rgb(245 81 105)'],
        borderColor: 'gray',
        borderWidth: 0.3
      }
    ]
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context: any) {
            return `${context.parsed} %`;
          }
        }
      },
      legend: {
        display: false
      }
    }
  };

  return (
    <div className="min-h-[60px] max-h-[160px] flex-grow-0">
      {chartData && (
        <Doughnut
          data={data}
          options={options}
          data-testid="SummaryCharts"
          className="mb-1 w-auto max-h-[50px]"
        />
      )}
      <div className="metricsCardLabel">
        {label}

        {tooltip && (
          <span>
            <Tooltip textStyle="ml-1 w-40" text={tooltip}>
              <FaInfoCircle className="ml-2 mb-[4px]" />
            </Tooltip>
          </span>
        )}
      </div>
      <div className={`${!isMain ? 'flexRow' : ''}`}>
        <div
          className={`metricsCardDescription ${isMain ?? ''} ${isMain ? 'text-neutral' : 'text-primary'}`}
        >
          {description}
        </div>
        <div className={`${isMain ? 'text-neutral-500' : 'text-primary'}`}>
          {score}
        </div>
      </div>
    </div>
  );
};

const SummaryMetrics = ({ startDate, endDate }: SummaryMetricsProp) => {
  const { useReport } = useApiService();

  const { data, isLoading } = useReport({
    startDate,
    endDate
  });

  if (isLoading) return <p>Loading...</p>;
  if (!data?.length || !data) return null;

  const normalised = Object.assign(
    {},
    ...data.map((x: SummaryMetric) => ({ [x.slug]: x.value }))
  );

  if (!Object.values(normalised).filter((x) => !!x).length) return null;

  return (
    <div className="mt-2 w-full flex flex-col lg:flex-row justify-between gap-[20px]">
      <div className="flex flex-col gap-[20px] mlg:flex-row lg:w-2/3">
        <div className="metricsCardWrapper border border-border w-full bg-custom-bg">
          <MetricsCard
            label="Incidents"
            tooltip="Breaching versions of content with at least one community report or flag within the thresholds, over total contents."
            description={getMetricsDescription(
              normalised['incidents-to-contents-ratio']
            )}
            chartData={normalised['incidents-to-contents-ratio']}
            overLabel="Contents"
            isMain="metricsCardMain"
            score={normalised.incidents}
          />
          <hr className={`metricsCardSeparator border-border border`} />
          <div>
            <MetricsCard
              label="Flagged"
              description={getMetricsDescription(
                normalised['flags-to-incidents-ratio']
              )}
              score={normalised.flags}
            />
            <MetricsCard
              label="Reported"
              description={getMetricsDescription(
                normalised['community-reports-to-incidents-ratio']
              )}
              score={normalised['community-reports']}
            />
          </div>
          <div>
            <MetricsCard label="Queued" />
            <MetricsCard
              label="Optimised"
              description={getMetricsDescription(
                normalised['autodismissed-to-incidents']
              )}
              score={normalised.autodismissed}
            />
          </div>
        </div>
        <div className="metricsCardWrapper border border-border flex-auto w-full bg-custom-bg">
          <MetricsCard
            label="Reviews"
            tooltip="Actioned incidents, via dismissal or enforcement, over open incidents."
            description={getMetricsDescription(
              normalised['reviews-to-incidents-ratio']
            )}
            chartData={normalised['reviews-to-incidents-ratio']}
            overLabel="Open incidents"
            isMain="metricsCardMain"
            score={normalised.reviews}
          />
          <hr className={`metricsCardSeparator border-border border`} />
          <div>
            <MetricsCard
              label="Dismissed"
              score={normalised['reviews-dismissed']}
            />
            <MetricsCard
              label="Enforced"
              score={normalised['reviews-enforced']}
            />
          </div>
          <div>
            <MetricsCard
              label="Skipped"
              description={getMetricsDescription(
                normalised['skips-to-manual-reviews-ratio']
              )}
              score={normalised.skips}
            />
            <MetricsCard
              label="TAT (avg)"
              tooltip="Turnaround time (average)"
              score={
                formatDuration(
                  normalised['tat-avg'],
                  DurationUnit.Minute
                ) as any
              }
            />
          </div>
        </div>
      </div>
      <div className="metricsCardWrapper border border-border w-full lg:w-1/3 bg-custom-bg">
        <MetricsCard
          label="Enforcements"
          tooltip="Items manually or automatically enforced, over closed incidents."
          description={getMetricsDescription(
            normalised['enforcements-to-incidents-closed-ratio']
          )}
          chartData={normalised['enforcements-to-incidents-closed-ratio']}
          overLabel="Closed incidents"
          isMain="metricsCardMain"
          score={normalised.enforcements}
        />
        <hr className={`metricsCardSeparator border-border border`} />
        <div>
          <MetricsCard
            label="On Content"
            description={getMetricsDescription(
              normalised['content-enforcements-to-incidents-closed-ratio']
            )}
            score={normalised['content-enforcements']}
          />
          <MetricsCard
            label="On User"
            description={getMetricsDescription(
              normalised['author-enforcements-to-incidents-closed-ratio']
            )}
            score={normalised['author-enforcements']}
          />
        </div>
      </div>
    </div>
  );
};

export { SummaryMetrics };
