import { MdEdit, MdDelete } from 'react-icons/md';

const ShowNewProperty = ({
  setShowAddParent,
  setParent,
  metadata,
  setShowAddAuthor,
  setAuthor,
  author,
  setShowAddMetadata,
  setMetadata,
  parent
}: any) => {
  return (
    <div className="flex gap-6 flex-col">
      {parent && (
        <div className="flex gap-3 flex-col">
          <div className="text-lg flex gap-3">
            Parent
            <MdEdit
              className="icon"
              size="24"
              onClick={() => setShowAddParent(true)}
            />
            <MdDelete
              onClick={() => setParent(null)}
              className="icon"
              size="24"
            />
          </div>
          <div className="min-w-fit flex gap-3 items-center">
            <div className="border border-border rounded-md p-2 gap-2 flex">
              <span>ID: {parent.id}</span>
              <span>Type: {parent.type}</span>
            </div>
          </div>
        </div>
      )}
      {author && (
        <div className="flex gap-3 flex-col">
          <div className="text-lg flex gap-3">
            Author
            <MdEdit
              className="icon"
              size="24"
              onClick={() => setShowAddAuthor(true)}
            />
            <MdDelete
              onClick={() => setAuthor(null)}
              className="icon"
              size="24"
            />
          </div>
          <div className="min-w-fit flex gap-3 items-center">
            <div className="border border-border rounded-md p-2 gap-2 flex">
              <span>{author}</span>
            </div>
          </div>
        </div>
      )}
      {!!metadata?.size && (
        <div className="flex gap-3 flex-col">
          <div className="text-lg flex gap-3">
            Metadata
            <MdEdit
              className="icon"
              size="22"
              onClick={() => setShowAddMetadata(true)}
            />
            <MdDelete
              onClick={() => setMetadata(new Set())}
              className="icon"
              size="24"
            />
          </div>
          <div className="min-w-fit flex gap-3 items-center">
            <div className="border border-border rounded-md p-2">
              {[...metadata].join(', ')}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export { ShowNewProperty };
