import { FiEyeOff, FiEye } from 'react-icons/fi';
import { useContent } from '@context/ContentProvider';
import { useState, useEffect } from 'react';
import { GiExpand } from 'react-icons/gi';
import { Dialog } from '@components/shared/Dialog';

type Props = {
  content: string;
  width: string;
  expand?: boolean;
};

const ContentImage = ({ content, width, expand = true }: Props) => {
  const { contentBlur, contentGrayscale } = useContent();
  const [isBlurred, setIsBlurred] = useState(contentBlur);
  const [isGrayscaled, setIsGrayscaled] = useState(contentGrayscale);
  const [show, setShow] = useState<boolean>(false);
  const [imgURL, setImgURL] = useState<string>();

  useEffect(() => {
    setIsBlurred(contentBlur);
  }, [contentBlur]);

  useEffect(() => {
    setIsGrayscaled(contentGrayscale);
  }, [contentGrayscale]);

  useEffect(() => {
    setIsBlurred(contentBlur);
    setIsGrayscaled(contentGrayscale);
  }, [content]);

  useEffect(() => {
    setImgURL(content);
  }, []);

  const blur = Math.floor(Math.min(1, parseFloat(isBlurred)) * 15);

  const filter = `blur(${blur}px) grayscale(${isGrayscaled})`;

  return (
    <div className="relative rounded-md border-border border overflow-hidden">
      <img
        src={imgURL}
        style={{
          width: width,
          maxWidth: '100%',
          filter: filter
        }}
        alt="Image content"
        data-testid="thumbnailImage"
      ></img>

      <div className="absolute top-1 right-1">
        <div>
          {parseFloat(isBlurred) || parseFloat(isGrayscaled) ? (
            <FiEyeOff
              className="text-2xl cursor-pointer bg-custom-bg rounded-md p-2 mb-2 w-8 h-8 flex items-center justify-center shadow-md"
              onClick={() => {
                setIsBlurred('0');
                setIsGrayscaled('0');
              }}
            />
          ) : (
            <FiEye
              className="text-2xl cursor-pointer bg-custom-bg rounded-md p-2 mb-2 w-8 h-8 flex items-center justify-center shadow-md"
              onClick={() => {
                setIsBlurred(contentBlur);
                setIsGrayscaled(contentGrayscale);
              }}
            />
          )}
        </div>
        {expand && (
          <div>
            <GiExpand
              onClick={() => setShow(true)}
              className="text-2xl cursor-pointer bg-custom-bg rounded-md p-2 mb-2 w-8 h-8 flex items-center justify-center shadow-md"
            />
          </div>
        )}
      </div>
      <Dialog show={show} close={() => setShow(false)}>
        <div className="m-3">
          <ContentImage
            key={`${content}--dl`}
            content={content}
            width={width}
            expand={false}
          />
        </div>
      </Dialog>
    </div>
  );
};

export { ContentImage };
