import { KeywordChip } from '@components/Settings/Strategies/KeywordChip';
import { Button, SubmitButtons } from '@components/shared/Buttons';
import { useState } from 'react';
import { FaPlus } from 'react-icons/fa6';

const StringField = ({
  type,
  setter,
  args,
  setIncludeSingle,
  includeSingle,
  isSingleArg = false,
  keyTo,
  edit,
  isNewCriteria
}: {
  type: string;
  setter: any;
  args: any;
  setIncludeSingle: (val: boolean) => void;
  includeSingle: boolean;
  isSingleArg?: boolean;
  keyTo: string;
  edit: boolean;
  isNewCriteria?: boolean;
}) => {
  const [newArg, setNewArg] = useState<string>('');

  const handleArg = (arg: string) => {
    if (isSingleArg) {
      setter(args === arg ? '' : arg);
      return;
    }
    const updatedArgs = new Set(args);
    updatedArgs.has(arg) ? updatedArgs.delete(arg) : updatedArgs.add(arg);
    setter(updatedArgs);
  };

  const addNewArg = () => {
    handleArg(newArg);
    setNewArg('');
  };

  const renderArgs = () => {
    if (!isSingleArg) {
      return [...args].map((arg) => (
        <KeywordChip
          keyword={arg}
          key={arg}
          style="py-0.5"
          onClick={edit ? () => handleArg(arg) : undefined}
        />
      ));
    } else if (args) {
      return (
        <KeywordChip
          keyword={args}
          key={args}
          style="py-0.5"
          onClick={edit ? () => handleArg(args) : undefined}
        />
      );
    }
    return null;
  };

  return (
    <div className="w-full flex flex-row gap-1">
      {keyTo && (
        <div className="p-1 h-10 flex items-center px-2 w-20 overflow-hidden">
          {type}:
        </div>
      )}
      {keyTo && (
        <div className="border-[1px] border-neutral-300 p-1 w-full rounded-md flex gap-2 h-10 items-center">
          <div className="flex flex-row gap-1">{renderArgs()}</div>
          <input
            className="bg-transparent pl-2 w-full focus:outline-none"
            placeholder={edit ? 'New arguments...' : ''}
            value={newArg}
            onChange={(e) => setNewArg(e.target.value)}
            disabled={!edit}
          />
          {edit && (
            <Button
              onClick={addNewArg}
              type="icon"
              tooltip="Add new argument"
              tooltipStyle="w-20"
              disabled={!newArg.length}
            >
              <FaPlus size={14} />
            </Button>
          )}
        </div>
      )}
      {!keyTo && isNewCriteria && (
        <div className="flex justify-between w-full nav-link__text items-center">
          <div className="cursor-pointer flex gap-4 capitalize text-center">
            Include?
          </div>
          <label className="relative cursor-pointer ml-2 flex items-center">
            <input
              type="checkbox"
              disabled={!edit}
              checked={includeSingle === true}
              onChange={() => setIncludeSingle(!includeSingle)}
              className="sr-only peer"
            />
            <div className="w-11 h-6 rounded-xl bg-neutral-200 text-cta border-neutral-200 border-[1.5px] peer peer-checked:after:translate-x-full peer-checked:after:bg-custom-bg after:bg-cta after:absolute after:top-[2px] after:start-[2px] after:rounded-xl after:h-5 after:w-5 after:transition-all peer-checked:bg-cta"></div>
          </label>
        </div>
      )}
    </div>
  );
};

const StringCriteria = ({
  edit,
  setEdit,
  criterion,
  submitEdit,
  keyTo,
  isNewCriteria,
  singleVal,
  operation
}: {
  edit: boolean;
  setEdit: (edit: boolean) => void;
  criterion: any;
  submitEdit: (operation: string, changes: any) => void;
  keyTo: string;
  isNewCriteria?: boolean;
  singleVal?: boolean;
  operation: string;
}) => {
  const { include, exclude } = criterion || {};
  const isSingleArg = singleVal;
  const [includeSingle, setIncludeSingle] = useState<boolean>(criterion?.new);
  const [pendingExcludeArg, setPendingExcludeArg] = useState<string>(
    isSingleArg && exclude?.[keyTo]
  );
  const [pendingIncludeArg, setPendingIncludeArg] = useState<string>(
    isSingleArg && include?.[keyTo]
  );
  const [pendingExcludeArgs, setPendingExcludeArgs] = useState<Set<string>>(
    new Set(exclude?.[keyTo] || [])
  );
  const [pendingIncludeArgs, setPendingIncludeArgs] = useState<Set<string>>(
    new Set(include?.[keyTo] || [])
  );

  const handleClose = () => {
    setEdit(false);
  };

  return (
    <div className={`w-full ${!keyTo ? 'flex flex-row items-center' : ''}`}>
      <div className="flex flex-col gap-2">
        <StringField
          setter={isSingleArg ? setPendingIncludeArg : setPendingIncludeArgs}
          args={isSingleArg ? pendingIncludeArg : pendingIncludeArgs}
          type={'Include'}
          isNewCriteria={isNewCriteria}
          setIncludeSingle={setIncludeSingle}
          includeSingle={includeSingle}
          isSingleArg={isSingleArg}
          keyTo={keyTo}
          edit={edit}
        />
        {keyTo && (
          <StringField
            setter={isSingleArg ? setPendingExcludeArg : setPendingExcludeArgs}
            args={isSingleArg ? pendingExcludeArg : pendingExcludeArgs}
            type={'Exclude'}
            setIncludeSingle={setIncludeSingle}
            includeSingle={includeSingle}
            isSingleArg={isSingleArg}
            keyTo={keyTo}
            edit={edit}
          />
        )}
      </div>
      {edit && (
        <div className={`w-full flex justify-end gap-2 ${keyTo ? 'mt-3' : ''}`}>
          <SubmitButtons
            onClose={!isNewCriteria ? handleClose : undefined}
            onSubmit={() =>
              submitEdit(operation, {
                changes: {
                  include: !isSingleArg
                    ? Array.from(pendingIncludeArgs)
                    : pendingIncludeArg,
                  exclude: !isSingleArg
                    ? Array.from(pendingExcludeArgs)
                    : pendingExcludeArg
                },
                keyName: keyTo
              })
            }
            submitLabel={'Save'}
            isLoading={false}
            disabled={!keyTo && !includeSingle}
          />
        </div>
      )}
    </div>
  );
};

export { StringCriteria };
