import { useEffect, useState, Fragment } from 'react';
import { Alert } from '@components/shared/Alert';
import { Dialog } from '@components/shared/Dialog';
import { useSearchParams } from 'react-router-dom';
import { useApiService } from '@api/services';
import { Loader } from '@components/shared/Loader';
import { useApiClient } from '@hooks/useApiClient';
import { Button, SubmitButtons } from '@components/shared/Buttons';
import { ApiKey } from '@api/types';
import { FaTriangleExclamation } from 'react-icons/fa6';
import { Header } from '@components/shared/Header';
import { useUser } from '@context/UserProvider';
import { FaTrashAlt } from 'react-icons/fa';

const ApiKeys = () => {
  const [searchParams] = useSearchParams();
  const platformId = searchParams.get('pid-setting') || '';
  const { platforms } = useUser();
  const platform = platforms?.find((p) => p.id.toString() == platformId);
  const apiKeyFormInitialState = { label: '', token: '', evnVar: '' };
  const [apiKeyForm, setApiKeyForm] = useState(apiKeyFormInitialState);
  const formErrInitialState = { status: false, message: '' };
  const [formError, setFormError] = useState(formErrInitialState);
  const [showCreateAPIKeyModal, setShowCreateAPIKeyModal] = useState(false);
  const [showPreviewAPIKeyModal, setShowPreviewAPIKeyModal] = useState(false);
  const [showDeleteAPIKeyModal, setShowDeleteAPIKeyModal] = useState(false);
  const [apikeySelected, setApikeySelected] = useState<ApiKey>();
  const { useApiKeys, postDeleteApikey } = useApiService();
  const apiClient = useApiClient();

  const { data: apiKeys, refetch, isFetching } = useApiKeys({ platformId });

  const toggleCreateAPIKeyModal = () =>
    setShowCreateAPIKeyModal(!showCreateAPIKeyModal);

  const togglePreviewAPIKeyModal = () => {
    setShowPreviewAPIKeyModal(!showPreviewAPIKeyModal);
    setApiKeyForm(apiKeyFormInitialState);
  };

  useEffect(() => {
    if (apiKeyForm.label === '') {
      setFormError({ status: true, message: 'Label is required' });
    } else if (apiKeys?.find((key: any) => key?.label === apiKeyForm?.label)) {
      setFormError({ status: true, message: 'Label must be unique' });
    } else {
      setFormError(formErrInitialState);
    }
  }, [apiKeyForm.label]);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    toggleCreateAPIKeyModal();
    apiClient
      .post(`/platforms/${platformId}/api-keys`, apiKeyForm)
      .then(({ data }) => {
        setApiKeyForm({
          ...apiKeyForm,
          token: data.unhashed_api_key,
          evnVar: `export CHECKSTEP_API_KEY=${data.unhashed_api_key}`
        });
        setShowPreviewAPIKeyModal(true);
        refetch();
      });
  };

  const handleDelete = () => {
    if (apikeySelected) {
      postDeleteApikey.mutate(apikeySelected.id.toString(), {
        onSuccess: async () => {
          await refetch();
          setShowDeleteAPIKeyModal(false);
        }
      });
    }
  };

  if (!apiKeys) return <Loader />;

  return (
    <>
      <Dialog
        show={showCreateAPIKeyModal}
        close={toggleCreateAPIKeyModal}
        dialogStyle="w-1/3 bg-custom-bg"
      >
        <form onSubmit={handleSubmit}>
          <div className="font-bold mb-4">Generate API Key</div>
          <div>
            <input
              type="text"
              placeholder="Enter Label..."
              className="roundedContainer w-full"
              value={apiKeyForm.label}
              onChange={(e) =>
                setApiKeyForm({ ...apiKeyForm, label: e.target.value })
              }
            />
            <p className="text-red mt-2 ml-1 h-4">{formError.message}</p>
          </div>
          <Button
            type="primarySmall"
            style="mt-3 font-bold "
            disabled={!!formError.message}
            onClick={() => {}}
            title="Create"
          />
        </form>
      </Dialog>
      <Dialog
        show={showPreviewAPIKeyModal}
        close={togglePreviewAPIKeyModal}
        dialogStyle="bg-custom-bg"
      >
        <h4 className="font-bold text-cta mb-3">API Key</h4>
        <div>
          <Alert variant="danger">
            You won't be able to view this page again.
          </Alert>
          <div className="my-2">
            <div className="font-bold">Label</div>
            <input
              type="text"
              className="roundedContainer w-full mt-1"
              readOnly
              defaultValue={apiKeyForm.label}
            />
          </div>
          <div className="mb-2">
            <div className="font-bold">Token</div>
            <div className="flex gap-2 items-center">
              <input
                type="text"
                className="roundedContainer w-full mt-1"
                readOnly
                defaultValue={apiKeyForm.token}
              />
              <Button
                onClick={() => navigator.clipboard.writeText(apiKeyForm.token)}
                type="secondarySmall"
                title="Copy"
              />
            </div>
          </div>
          <div className="mb-2">
            <div className="font-bold">As environment variable</div>
            <div className="flex gap-2 items-center">
              <input
                type="text"
                className="roundedContainer w-full mt-1"
                readOnly
                defaultValue={apiKeyForm.evnVar}
              />
              <Button
                onClick={() => navigator.clipboard.writeText(apiKeyForm.evnVar)}
                type="secondarySmall"
                title="Copy"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end">
          <Button
            style="bg-error mt-3 font-bold text-white p-3 rounded-md"
            onClick={togglePreviewAPIKeyModal}
            title="I've copied my API token"
          />
        </div>
      </Dialog>

      <Header>
        <div className="flex flex-col pt-2">
          <div className="text-cta font-semibold">{platform?.name}</div>
          <h2 className="mb-3 text-[28px] lg:text-[32px]">API Keys</h2>
        </div>
      </Header>
      <div className="p-8">
        <div className="mb-3">
          {apiKeys?.map((item: any) => (
            <Fragment key={item?.id || item?.code}>
              <div className="flex justify-between w-full p-3 border-b-[1px] border-border">
                <span className="ml-2">{item?.label}</span>
                <button
                  className="icon w-7 h-7"
                  onClick={() => {
                    setApikeySelected(item);
                    setShowDeleteAPIKeyModal(true);
                  }}
                >
                  <FaTrashAlt size="14" />
                </button>
              </div>
            </Fragment>
          ))}
        </div>
        <div className="flex justify-end mt-8">
          <Button
            type="primary"
            onClick={toggleCreateAPIKeyModal}
            title="Create API Key"
          />
        </div>
      </div>
      <Dialog
        show={showDeleteAPIKeyModal}
        close={() => setShowDeleteAPIKeyModal(false)}
        dialogStyle="w-1/2 2xl:w-1/3 bg-custom-bg"
      >
        <div>
          <div className="text-2xl mb-2 font-semibold flex justify-center">
            Delete API Key
          </div>
          <div className="flex justify-center my-4">
            <div className="flex justify-center w-[60px] h-[60px] bg-alert-100 rounded-[50px]">
              <FaTriangleExclamation className="text-alert" size="2rem" />
            </div>
          </div>
          <div>
            Are you sure you want to delete the{' '}
            <span className="font-semibold">{apikeySelected?.label}</span> api
            key? Once deleted, you won't be able to send content with this API
            key, and it cannot be recovered
          </div>
          <div className="flex justify-end gap-2 mt-4">
            <SubmitButtons
              onClose={() => setShowDeleteAPIKeyModal(false)}
              onSubmit={handleDelete}
              submitLabel="Delete"
              isLoading={isFetching}
              submitStyle="bg-error p-2 text-white rounded-md"
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ApiKeys;
