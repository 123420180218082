import { ReactNode } from 'react';
import { useApiService } from '@api/services';
import { PolicyHistory, PolicyRuleLog } from '@api/types';
import { getLocaleTime } from '@utils/date';
import { FaExchangeAlt, FaMinusCircle } from 'react-icons/fa';
import { AiFillExclamationCircle } from 'react-icons/ai';
import { IoIosAddCircle } from 'react-icons/io';
import { Loader } from '@components/shared/Loader';
import { useUser } from '@context/UserProvider';
import { getModeratorEmail } from '@utils/string';
import { Percentage } from '@components/shared/Percentage';

type Props = {
  policy?: string;
};

type RuleListType = {
  list?: PolicyRuleLog[];
  action: string;
  icon: ReactNode;
};

const RulesList = ({ list, action, icon }: RuleListType) => {
  if (!list?.length) return null;

  return (
    <div className="flex">
      <span className="mr-2">{icon}</span>
      <div className="flex flex-col">
        {list.map((x: PolicyRuleLog) => (
          <div key={x.value.label}>
            <span>{x.value?.label}:</span>{' '}
            {action !== 'edited' && (
              <>
                <Percentage val={x.value?.lower_threshold} convert={true} />;{' '}
                <Percentage val={x.value?.upper_threshold} convert={true} />;{' '}
                {x.value?.content_type}
              </>
            )}
            {action === 'edited' && (
              <>
                <span className="text-error">
                  <Percentage
                    val={x.oldValue?.lower_threshold}
                    convert={true}
                  />
                  ;{' '}
                  <Percentage
                    val={x.oldValue?.upper_threshold}
                    convert={true}
                  />
                </span>
                {'=>'}{' '}
                <span className="text-success">
                  <Percentage val={x.value?.lower_threshold} convert={true} />;{' '}
                  <Percentage val={x.value?.upper_threshold} convert={true} />
                </span>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const RulesHistory = ({ policy }: Props) => {
  const { usePolicyHistory } = useApiService();
  const { data, error, isLoading } = usePolicyHistory({ code: policy ?? '' });
  const { moderators } = useUser();

  if (isLoading) return <Loader />;
  if (!data?.length)
    return <h4 className="p-4">No Policy rule history found</h4>;
  if (error) return <h4 className="p-4">{JSON.stringify(error)}</h4>;

  return (
    <div>
      <h4 className="border-b border-border pb-3 mb-5">Rules History</h4>
      <div className="relative ml-6 mt-4 before:absolute before:ml-[18.5px] before:h-full before:w-[3px] before:bg-gray">
        <div className="relative">
          {data?.map((x: PolicyHistory) => (
            <div className="flex space-x-4 mb-3" key={x.ts.toString()}>
              <div className="flex mr-2 items-center justify-center w-10 h-10 rounded-xl bg-gray">
                <FaExchangeAlt
                  size={22}
                  color="black"
                  data-testid="PolicyHistoryIcon"
                />
              </div>
              <div className="w-[85%]">
                <span className="">
                  <span className="text-lg font-semibold mr-2">
                    {getLocaleTime(x.ts.toString())}
                  </span>
                  <div>{getModeratorEmail(x.userId, moderators)}</div>
                </span>
                <div className="flex flex-col gap-2 mt-2 p-2.5">
                  <RulesList
                    list={x.added}
                    action="added"
                    icon={<IoIosAddCircle size="22" color="#38D900" />}
                  />
                  <RulesList
                    list={x.edited}
                    action="edited"
                    icon={<AiFillExclamationCircle size="22" color="#FCA701" />}
                  />
                  <RulesList
                    list={x.deleted}
                    action="deleted"
                    icon={
                      <FaMinusCircle
                        size="19"
                        color="red"
                        className="ml-[1px]"
                      />
                    }
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export { RulesHistory };
