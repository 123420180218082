import { EvaluationExt, pViolationType } from '@typeDef/Review';
import { useApiService } from '@api/services';
import { Tooltip } from '@components/shared/Tooltip/Tooltip';
import { strategiesIcons } from '@components/Policy/helper';
import { Button } from '@components/shared/Buttons';
import { useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { getValueFromList } from '../utils';
import { useUser } from '@context/UserProvider';
import { Dialog } from '@components/shared/Dialog';
import { LabelsManagement } from '@components/shared/LabelsManagement';

type Props = {
  evaluations: pViolationType;
  selectedProperty?: string;
  setSelectedProperty: (val: any) => void;
};

const ViolationTab = ({
  evaluations,
  selectedProperty,
  setSelectedProperty
}: Props) => {
  const { useStrategies } = useApiService();
  const [evaluationsList] = useState(evaluations);
  const { data: strategyList } = useStrategies();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [label, setLabel] = useState<string>();

  const getOpenState = () => {
    if (evaluationsList?.policy === 'Non violating') {
      return false;
    }
    return true;
  };
  const [openTab, setOpenTab] = useState(getOpenState());
  const { policies, user } = useUser();

  const handleTooltip = (x: EvaluationExt) => {
    if (!strategyList) return null;
    const selectedStrategy = strategyList?.find((y) => x?.strategy === y?.slug);

    const icon = (strategiesIcons as any)?.[
      selectedStrategy?.type || 'Strategy Type'
    ];

    if (!icon) return null;
    return (
      <Tooltip text={selectedStrategy?.name} textStyle="w-40">
        <img
          alt="img"
          width="20"
          className="max-h-[21px]"
          src={
            (strategiesIcons as any)?.[
              selectedStrategy?.type || 'Strategy Type'
            ]
          }
        />
      </Tooltip>
    );
  };
  const getPolicy = getValueFromList(policies, 'code', 'name');
  const canManageRule = (label?: string) => {
    if (!user?.isUserAdmin || !label) return false;
    return label !== 'error';
  };

  return (
    <>
      <Button
        style="w-full flex justify-between p-2.5 hover:bg-transparent"
        onClick={() => setOpenTab(!openTab)}
      >
        <div className="text-md font-semibold">
          {getPolicy(evaluationsList?.policy)}
        </div>
        <div>
          {openTab && <IoIosArrowUp size={18} />}
          {!openTab && <IoIosArrowDown size={18} />}
        </div>
      </Button>
      {openTab &&
        evaluationsList?.evaluation?.map((x: EvaluationExt, index: number) => (
          <div
            key={`${x?.label}-${x?.field}-${x?.annotation}-${x?.score}-${x?.loc}-${x?.strategy}-${index}`}
            className={`flex items-center gap-2 w-full px-2.5 py-1 ${
              selectedProperty === x?.field && 'bg-highlight'
            }`}
          >
            <Button
              style="grid grid-cols-4 w-full ml-1 cursor-pointer hover:bg-transparent"
              onClick={() => {
                x?.field !== '*' && setSelectedProperty(x?.field);
                setLabel(x.label);
                canManageRule(x?.label) && setShowDialog(true);
              }}
              hiddenTitle={`${x.label} evaluation click`}
            >
              <div className="flex col-span-3">
                {handleTooltip(x)}
                <p className="break-words line-clamp-1 ml-2 text-sm font-normal mr-1 text-start hover:line-clamp-2">
                  {x?.label}
                </p>
              </div>
              <p className="flex justify-end text-sm font-normal">
                {(x?.score * 100)?.toFixed(2)}%
              </p>
            </Button>
          </div>
        ))}
      <Dialog show={showDialog} close={() => setShowDialog(false)}>
        <LabelsManagement label={label} />
      </Dialog>
    </>
  );
};

export { ViolationTab };
