import { useState } from 'react';
import { ContentCase, ContentField } from '@api/types';
import { ContentFields } from '@components/shared/Case/Content';
import { getLocaleTime } from '@utils/date';
import { Button } from '@components/shared/Buttons';

const Siblings = ({ contentCase }: { contentCase?: ContentCase }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const contents = contentCase?.contents?.at(-1);
  if (!contentCase || !contents) return null;
  return (
    <div className="grid grid-cols-12 items-center w-full cursor-default">
      <div className="w-full col-span-3 px-1 py-1 flex flex-col text-xs max-w-[95%] text-neutral-700">
        <div className="overflow-hidden text-ellipsis font-bold">
          {contents?.author}
        </div>
        <div className="leading-3 font-light">
          {getLocaleTime(contentCase?.ts.toString())}
        </div>
        {contents.fields && contents?.fields?.length > 1 && (
          <Button
            onClick={() => setExpanded(!expanded)}
            style="w-min text-primary"
          >
            {expanded ? 'Collapse' : 'Expand'}
          </Button>
        )}
      </div>
      <div className="pl-3 col-span-8">
        {contents?.fields && (
          <ContentFields
            fields={
              expanded
                ? contents?.fields
                : ([
                    contents?.fields?.find(
                      (field: any) => field?.type === 'text'
                    ) ?? contents?.fields?.[0]
                  ] as ContentField[] | undefined)
            }
          />
        )}
      </div>
    </div>
  );
};

export { Siblings };
