import { useState, useEffect } from 'react';
import { useUser } from '@context/UserProvider';
import { FaInfoCircle } from 'react-icons/fa';
import { FaCheck } from 'react-icons/fa6';
import { useHotkeysContext } from 'react-hotkeys-hook';
import { assignHotkey } from '@components/Review/utils';
import { Dialog } from '@components/shared/Dialog';
import { Policy } from '@api/types';
import { Button } from '@components/shared/Buttons';
import { PolicyView } from '@components/Policy/PolicyView';

type PolicyListProps = {
  onClick: (val: string) => void;
  violations: Set<string>;
  confirmPolicies: () => void;
  setSelectedAction: (val: object) => void;
};

const PolicyList = ({
  onClick,
  violations,
  confirmPolicies,
  setSelectedAction
}: PolicyListProps) => {
  const { policies } = useUser();
  const { enableScope, disableScope } = useHotkeysContext();
  const [selectedDialog, setSelectedDialog] = useState<string>('');
  const [tabActive, setTabActive] = useState<string>('description');

  useEffect(() => {
    enableScope('policies');
    disableScope('actions');
    disableScope('confirmAction');
    return () => {
      enableScope('actions');
      disableScope('policies');
    };
  }, []);

  const getBind = (x: number) => {
    if (x < 10) return x.toString();
    if (x < 19) return `ctrl+${(x - 9).toString()}`;
    return `shift+${(x - 18).toString()}`;
  };

  assignHotkey('esc', setSelectedAction, '', 'policies');
  assignHotkey('enter', confirmPolicies, '', 'policies');

  const list = policies?.map((x: Policy) => ({
    ...x,
    rank: policies?.length - x.rank + 1
  }));

  list?.map((x: Policy) =>
    assignHotkey(getBind(x.rank), onClick, x.code, 'policies')
  );

  const onSelectPolicyInfo = (e: React.MouseEvent, code: string) => {
    e.stopPropagation();
    setSelectedDialog(code);
  };

  const getItemRank = (rank: number) => {
    if (rank < 10) {
      return <kbd className="mr-2 bg-bg-3 text-fg-1">{rank}</kbd>;
    } else if (rank < 19) {
      return <kbd className="mr-2 bg-bg-3 text-fg-1">ctrl+{rank - 9}</kbd>;
    } else {
      return <kbd className="mr-2 bg-bg-3 text-fg-1">shift+{rank - 18}</kbd>;
    }
  };

  if (!list) return null;
  return (
    <div autoFocus={true}>
      <div className="overflow-auto">
        {list
          .sort((a: Policy, b: Policy) => a.rank - b.rank)
          .map((item: Policy) => (
            <div
              key={item.code}
              data-testid="itemList"
              className="flex w-full py-1.5 text-left relative hover:bg-highlight hover:cursor-pointer"
              onClick={() => onClick(item.code)}
              style={
                violations?.has(item.code)
                  ? { backgroundColor: 'var(--primary-50)' }
                  : {}
              }
            >
              {violations?.has(item.code) ? (
                <div className="bg-cta flex-shrink-0 w-6 h-6 rounded-lg text-white flex items-center justify-center mr-2 ml-[12px]">
                  <FaCheck />
                </div>
              ) : (
                getItemRank(item.rank)
              )}
              <span className="mr-2 max-w-[60%]">{item.name}</span>
              <Button
                onClick={(e) => onSelectPolicyInfo(e, item.code)}
                hiddenTitle="Policy description reminder"
                style="self-center right-1 absolute hover:bg-transparent"
              >
                <FaInfoCircle size="20" />
              </Button>
              <Dialog
                show={selectedDialog === item.code}
                close={() => setSelectedDialog('')}
                dialogStyle="w-2/3 bg-custom-bg"
              >
                <h2 className="border-b border-lightgray py-3">
                  {item.name} - {item.code}
                </h2>
                <PolicyView
                  policy={item}
                  preview={true}
                  tabActive={tabActive}
                  setTabActive={setTabActive}
                />
              </Dialog>
            </div>
          ))}
      </div>
    </div>
  );
};

export { PolicyList };
